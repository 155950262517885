import { Input } from "../../Input";
import { connect } from "react-redux";
import language from "../../../language";

const TextInput = (props) => {
  return (
    <Input
      placeholder={
        language[props.defaultLanguage].default_placeholder_input +
        " " +
        props.label
      }
      label={props.label}
      type={props.type}
      min={props.min}
      max={props.max}
      helperText={props.description}
      // error={
      //   props.error[answer.objectTitle + "_psrp_" + key]
      // }
      // value={props[answer.objectTitle + "_psrp_" + key]}
      // onChange={(e) =>
      //   props.onChangeText({
      //     fieldName: answer.objectTitle + "_psrp_" + key,
      //     value: e,
      //     rowIndex: i,
      //     dataType: "multiAnswer",
      //   })
      // }
      // type={answer[key]}
      // disabled={
      //   answer[key] === "position" ||
      //   answer[key] === "department"
      // }
      required
    />
  );
};

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};
export default connect(mapStateToProps)(TextInput);
