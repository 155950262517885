import React from "react";
import { DropdownButton } from "../Button";

import icons from "../../constants/icons";
import { CardHeader } from "../Header";
import { connect } from "react-redux";
import language from "../../language";
import { Checkbox } from "../Input";
import { onAddFilter } from "../../actions/Filters";
import serviceCategoryQuery from "../../utils/queries/serviceQuery";
import formatSelectData from "../../utils/formatSelectData";
import { getStorage } from "../../utils/storage";

let copyData = [];

class ServiceCategoriesFilter extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      serviceCategories: [],
    };
  }

  componentDidMount = async () => {
    const user = await getStorage();
    await this.setState({ user });
    await this.getData(true);
  };

  onChangeText(field, e) {
    let { error } = this.state;

    delete error[field];

    this.setState({ error, [field]: e.target.value });
  }

  getData = async (isLoading) => {
    try {
      const { defaultLanguage } = this.props;

      this.setState({ isLoading });

      const { data, count } = await serviceCategoryQuery(defaultLanguage, {
        page: 1,
        limit: 100,
      });

      this.setState({
        data: formatSelectData(data, "name", "type"),
        totalPageCount: count,
        isLoading: false,
      });

      copyData = data.slice(0);
    } catch (error) {
      this.setState({
        isLoading: false,
      });
    }
  };

  onCheck(el) {
    let { filters } = this.props;
    let { serviceCategory = [] } = filters;

    serviceCategory = {
      value: el.value,
      label: el.label,
    };

    this.props.dispatch(
      onAddFilter({
        serviceCategory,
      })
    );
  }

  handleSearch(e) {
    const searchValue = e.target.value;
    this.setState({ searchValue });

    let results = [];
    copyData.forEach((el) => {
      if (
        JSON.stringify(el).toLowerCase().indexOf(searchValue.toLowerCase()) !==
        -1
      ) {
        results.push(el);
      }
    });

    this.setState({ data: results });
  }

  render() {
    return (
      <DropdownButton
        leftIcon={icons.category}
        text={language[this.props.defaultLanguage].service_categories}
        className="btn-transparent btn-bordered w-sm-100 btn-sm"
        selectedData={this.props?.filters?.serviceCategory}
        clickOutside
      >
        <CardHeader
          title={language[this.props.defaultLanguage].service_categories}
          borderBottom
          closeButton
          isSearch
        />
        <div className="dropdown-content">
          {this.state?.data?.map((el, i) => {
            return (
              <Checkbox
                label={el.label}
                key={i}
                onCheck={() => this.onCheck(el)}
                checked={
                  this.props?.filters?.serviceCategory?.value === el.value
                }
              />
            );
          })}
        </div>
      </DropdownButton>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  const { filters } = state.Filters;
  return {
    defaultLanguage,
    filters,
  };
};

export default connect(mapStateToProps)(ServiceCategoriesFilter);
