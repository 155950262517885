// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  src: "https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200";
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  font-display: block;
}
body,
html {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}
* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "Nunito", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: var(--font-size);
  overflow-y: hidden;
  overflow-x: hidden !important;
  background-color: var(--bg-color);
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AACA;EACE,2HAA2H;EAC3H,6BAA6B;EAC7B,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;AACrB;AACA;;EAEE,SAAS;EACT,UAAU;EACV,uBAAuB;AACzB;AACA;EACE,sBAAsB;AACxB;;AAEA;EACE,SAAS;EACT,iCAAiC;EACjC,mCAAmC;EACnC,kCAAkC;EAClC,2BAA2B;EAC3B,kBAAkB;EAClB,6BAA6B;EAC7B,iCAAiC;AACnC","sourcesContent":["@import url(\"https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&display=swap\");\n@font-face {\n  src: \"https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200\";\n  font-family: \"Material Icons\";\n  font-style: normal;\n  font-weight: 400;\n  font-display: block;\n}\nbody,\nhtml {\n  margin: 0;\n  padding: 0;\n  scroll-behavior: smooth;\n}\n* {\n  box-sizing: border-box;\n}\n\nbody {\n  margin: 0;\n  font-family: \"Nunito\", sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n  font-size: var(--font-size);\n  overflow-y: hidden;\n  overflow-x: hidden !important;\n  background-color: var(--bg-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
