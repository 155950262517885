import React from "react";
import { connect } from "react-redux";
import { Input, Select } from "../../Input";
import { Button } from "../../Button";
import language from "../../../language";
import toastMessage from "../../../utils/toastMessage";
import { fileExtensions, sizeLimits } from "../../../constants/document";
import { getStorage } from "../../../utils/storage";
import { apiInstance } from "../../../utils/axiosInstance";

class NewRequiredDocument extends React.Component {
  state = {
    error: {},
    documentName: "",
    sizeLimit: "",
  };

  componentDidMount() {
    const { dataIndex, serviceData } = this.props;

    if (
      dataIndex !== undefined &&
      serviceData?.files &&
      serviceData?.files?.length > 0
    ) {
      const split = serviceData?.files[dataIndex]?.name.split(".") || [];
      const extension = split[split.length - 1];
      const documentName = split.length > 0 ? split[0] : "";

      this.setState({
        documentName,
        fileExtension: {
          label: extension,
          value: extension,
        },
      });
    }
    this.setState({ ...this.props });
  }

  onChangeText(field, e) {
    let { error } = this.state;
    delete error[field];

    this.setState({
      [field]: e.target ? e.target.value : e,
      error,
    });
  }

  validateForm() {
    const { fileExtension, documentName, error } = this.state;
    const { defaultLanguage } = this.props;

    if (!fileExtension) {
      error.fileExtension = language[defaultLanguage].file_extension_required;
    }

    if (documentName === "") {
      error.documentName = language[defaultLanguage].document_name_required;
    }

    this.setState({ error });
  }

  onSubmit = async () => {
    let { defaultLanguage, serviceData } = this.props;
    let { files } = serviceData;

    if (!serviceData._id || !files) {
      return toastMessage(
        "error",
        language[defaultLanguage].service_is_missing
      );
    }

    await this.validateForm();

    if (Object.keys(this.state.error).length === 0) {
      let user = await getStorage();

      this.setState({
        isSubmitting: true,
      });

      const { fileExtension, documentName, dataIndex } = this.state;

      let method = "PUT";

      //update
      if (dataIndex !== undefined) {
        files[dataIndex].name = documentName + "." + fileExtension.value;
      } else {
        files.push({
          name: documentName + "." + fileExtension.value,
        });
      }

      const options = {
        method,
        url: `/service`,
        data: {
          id: serviceData._id,
          files,
        },
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      };

      apiInstance(options)
        .then((data) => {
          this.setState({
            isSubmitting: false,
          });

          toastMessage(
            "success",
            language[this.props.defaultLanguage][
              dataIndex !== undefined
                ? "update_required_document_success"
                : "add_required_document_success"
            ]
          );

          this.props.handleCloseModal();
          this.props.getData();
        })
        .catch((error) => {
          this.setState({
            isSubmitting: false,
          });

          toastMessage(
            "error",
            language[this.props.defaultLanguage][
              dataIndex !== undefined
                ? "error_update_required_document"
                : "error_add_required_document"
            ]
          );
        });
    }
  };

  render() {
    const { document_name, file_extension, select, document_name_placeholder } =
      language[this.props.defaultLanguage];
    return (
      <div>
        <div className="card-body">
          <Input
            multiline
            placeholder={document_name_placeholder}
            label={document_name}
            required
            value={this.state.documentName}
            onChange={(e) => this.onChangeText("documentName", e)}
            error={this.state.error.documentName}
          />
          <Select
            options={fileExtensions}
            placeholder={select}
            label={file_extension}
            required
            value={this.state.fileExtension}
            onChange={(e) => this.onChangeText("fileExtension", e)}
            error={this.state.error.fileExtension}
          />
        </div>
        <div className="modal-footer gap-2">
          <Button
            text={language[this.props.defaultLanguage].cancel}
            onPress={this.props.handleCloseModal}
            className="btn-default"
          />
          <Button
            text={language[this.props.defaultLanguage].save}
            onPress={this.onSubmit.bind(this)}
            isSubmitting={this.state.isSubmitting}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(NewRequiredDocument);
