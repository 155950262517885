// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.filters-container {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 1.5rem;
}

.filters-container .header {
  height: 40px;
  padding: 0 1rem;
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--text-color-10);
}

.filters-container .header .title {
  flex: 1 1;
}

.filters-container .filter-footer {
  display: flex;
  align-items: center;
  gap: 1rem;
  border-top: 1px solid var(--text-color-10);
  padding: 0.5rem;
  align-self: flex-end;
}

.filters-container .checkbox-container {
  max-width: 250px;
}

.filters-date-list .filters-date-list-item {
  min-height: 40px;
  display: flex;
  color: var(--primary-color);
  align-items: center;
}

.filters-date-list .filters-date-list-item:hover {
  opacity: 0.8;
  cursor: pointer;
}

.filters-date-list .filters-date-list-item.active {
  color: var(--primary-color);
}

.filters-date-list .filters-date-list-item .icon-container {
  display: none;
}

.filters-date-list .filters-date-list-item.active .icon-container {
  display: flex;
}
`, "",{"version":3,"sources":["webpack://./src/app/components/Filters/styles.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,SAAS;EACT,eAAe;EACf,mBAAmB;EACnB,qBAAqB;AACvB;;AAEA;EACE,YAAY;EACZ,eAAe;EACf,aAAa;EACb,mBAAmB;EACnB,6CAA6C;AAC/C;;AAEA;EACE,SAAO;AACT;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,SAAS;EACT,0CAA0C;EAC1C,eAAe;EACf,oBAAoB;AACtB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,aAAa;EACb,2BAA2B;EAC3B,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,aAAa;AACf","sourcesContent":[".filters-container {\r\n  display: flex;\r\n  gap: 1rem;\r\n  flex-wrap: wrap;\r\n  align-items: center;\r\n  margin-bottom: 1.5rem;\r\n}\r\n\r\n.filters-container .header {\r\n  height: 40px;\r\n  padding: 0 1rem;\r\n  display: flex;\r\n  align-items: center;\r\n  border-bottom: 1px solid var(--text-color-10);\r\n}\r\n\r\n.filters-container .header .title {\r\n  flex: 1;\r\n}\r\n\r\n.filters-container .filter-footer {\r\n  display: flex;\r\n  align-items: center;\r\n  gap: 1rem;\r\n  border-top: 1px solid var(--text-color-10);\r\n  padding: 0.5rem;\r\n  align-self: flex-end;\r\n}\r\n\r\n.filters-container .checkbox-container {\r\n  max-width: 250px;\r\n}\r\n\r\n.filters-date-list .filters-date-list-item {\r\n  min-height: 40px;\r\n  display: flex;\r\n  color: var(--primary-color);\r\n  align-items: center;\r\n}\r\n\r\n.filters-date-list .filters-date-list-item:hover {\r\n  opacity: 0.8;\r\n  cursor: pointer;\r\n}\r\n\r\n.filters-date-list .filters-date-list-item.active {\r\n  color: var(--primary-color);\r\n}\r\n\r\n.filters-date-list .filters-date-list-item .icon-container {\r\n  display: none;\r\n}\r\n\r\n.filters-date-list .filters-date-list-item.active .icon-container {\r\n  display: flex;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
