import React from "react";
import { connect } from "react-redux";
import language from "../../../language";
import { Tabs } from "../../Tabs";
import Documents from "../RequiredDocuments/Documents";
import Questionnaire from "../Questionnaire/Questionnaire";
import Translations from "../Translations/Translations";
import serviceQuery from "../../../utils/queries/serviceQuery";

class SingleService extends React.Component {
  state = {
    isLoading: false,
    page: 1,
    limit: 1,
    data: {},
    selected_data: [],
  };

  componentDidMount() {
    this.getData(true);
  }

  getData = async (isLoading) => {
    try {
      const { defaultLanguage, serviceId } = this.props;

      this.setState({ isLoading });

      const data = await serviceQuery(defaultLanguage, {
        id: serviceId,
      });

      let res = {};

      if (data?.length > 0) {
        res = data[0];
      }

      this.setState({
        data: res,
        isLoading: false,
      });
    } catch (error) {
      this.setState({
        isLoading: false,
      });
    }
  };

  render() {
    const { questionnaire } = language[this.props.defaultLanguage];
    return (
      <>
        <div className="card-body">
          <Tabs
            options={[
              {
                title: questionnaire,
                data: (
                  <Questionnaire
                    serviceId={this.props.serviceId}
                    serviceName={this.props.serviceName}
                  />
                ),
              },
            ]}
          />
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(SingleService);
