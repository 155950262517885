import React from "react";
import { Button } from "../Button";
import { Input } from "../Input";
import icons from "../../constants/icons";

const DeleteOperator = (props) => {
  return (
    <div className="card">
      <div className="card-body">
        <span>
          Type in the box below the word{" "}
          <b>
            <code title="Copy this text">{props.name}</code>
          </b>{" "}
          to confirm`
        </span>
        <br />

        <span>
          <small>
            OR: Type <b> DELETE</b>
          </small>
        </span>

        <Input
          leftIcon={icons.passcode}
          value={props.value}
          error={props.error}
          autocomplete="off"
          onChange={props.onChange}
        />
      </div>
      <div className="modal-footer">
        <Button
          text="Cancel"
          className="btn-default border"
          onPress={() => props.handleCloseModal()}
        />

        <Button
          text="Delete"
          disabled={props.value !== props.name && props.value !== "DELETE"}
          className="btn-primary"
          onPress={props.onDelete}
          isSubmitting={props.isDeleting}
        />
      </div>
    </div>
  );
};

export default DeleteOperator;
