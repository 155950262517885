import Row from "./Row";
import { Draggable } from "react-beautiful-dnd";

const TableData = (props) => {
  const {
    data,
    headers,
    rowPress,
    actions,
    draggable,
    handleDrag,
    handleStart,
    handleStop,
  } = props;

  return (
    <>
      {data.map((item, i) => {
        if (props.draggable) {
          if (!item._id) return;
          return (
            <Draggable draggableId={item._id} index={i} key={item._id}>
              {(provided, snapshot) => (
                <tr
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  className={
                    snapshot.isDragging
                      ? "dragging-container"
                      : "dropping-container"
                  }
                  style={{ ...provided.draggableProps.style }}
                  key={item._id}
                  nodeId={item._id}
                >
                  <Row
                    keyRowIndex={i}
                    item={item}
                    draggable={draggable}
                    headers={headers}
                    rowPress={rowPress}
                    actions={actions}
                    ref={item.innerRef}
                    onStart={handleStart}
                    onDrag={handleDrag}
                    onStop={handleStop}
                  />
                </tr>
              )}
            </Draggable>
          );
        }
        return (
          <tr key={i}>
            <Row
              keyRowIndex={i}
              item={item}
              draggable={draggable}
              headers={headers}
              rowPress={rowPress}
              actions={actions}
            />
          </tr>
        );
      })}
    </>
  );
};

export default TableData;
