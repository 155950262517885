import { clearStorage } from "../storage";

let timeout;

export const restartAutoReset = ({
  threshold = 30 * 60 * 1000,
  navigateTo,
}) => {
  if (timeout) {
    clearTimeout(timeout);
  }
  timeout = setTimeout(() => {
    clearStorage();
    window.location.href = navigateTo;
  }, threshold);
};
