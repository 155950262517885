import toastMessage from "../toastMessage";
import formatSelectData from "../formatSelectData";
import { getStorage } from "../storage";
import language from "../../language";
import { defineRole } from "../handleAccessRoles";
import { apiInstance } from "../axiosInstance";

export const fetchServiceCategories = async (lng = "english", params = {}) => {
  const { page, limit, type, categoryType, description } = params;
  try {
    const user = await getStorage();

    const { accessRole } = user;

    const canReadServiceCategories = true;
    //  defineRole({
    //   roles: accessRole,
    //   menu: "Service Categories",
    //   operation: "read",
    // });

    if (!canReadServiceCategories) return;

    const options = {
      method: "GET",
      url: `/serviceCategory`,
      params: {
        page: page,
        limit: limit,
        type: categoryType,
        institution: user.institution,
        description,
      },
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    console.log({
      page: page,
      limit: limit,
      type: categoryType,
      institution: user.institution,
      description,
    });

    const queryRes = await apiInstance(options);

    let { data, count } = queryRes.data;

    if (type === "dropdown") {
      return formatSelectData(queryRes.data, "name", "_id");
    }

    return !page && !limit ? queryRes.data : { data, count };
  } catch (error) {
    toastMessage("error", language[lng].unable_fetch_service_category);
  }
};

export const deleteServiceCategory = async (lng = "english", id) => {
  try {
    const user = await getStorage();

    const options = {
      method: "DELETE",
      url: `/serviceCategory`,
      data: {
        id,
      },
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    await apiInstance(options);

    toastMessage("success", language[lng].success_delete_service_category);
  } catch (error) {
    toastMessage("error", language[lng].error_delete_service_category);
  }
};

export default fetchServiceCategories;
