const Separator = (props) => {
  return (
    <div className="separator-container">
      <div className="icon">
        <span className={process.env.REACT_APP_ICONS_TYPE}>
          {props.leftIcon}
        </span>
      </div>
      <div>
        <span>{props.title}</span>
      </div>
    </div>
  );
};

export default Separator;
