import toastMessage from "../toastMessage";
import formatSelectData from "../formatSelectData";
import { getStorage } from "../storage";
import language from "../../language";
import { defineRole } from "../handleAccessRoles";
import { apiInstance } from "../axiosInstance";

const fetchData = async (lng = "english", { page, limit, type, name }) => {
  try {
    const user = await getStorage();

    const { accessRole } = user;

    const canRead = defineRole({
      roles: accessRole,
      menu: "Departments",
      operation: "read",
    });

    if (!canRead) return;

    const options = {
      method: "GET",
      url: `/department`,
      params: {
        page: page,
        limit: limit,
        name,
        institution: user.institution,
      },
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    const queryRes = await apiInstance(options);

    let { data, count } = queryRes.data;

    if (type === "dropdown") {
      return formatSelectData(queryRes.data, "name", "_id");
    }

    return !page && !limit ? queryRes.data : { data, count };
  } catch (error) {
    toastMessage("error", language[lng].unable_fetch_departments);
  }
};

export default fetchData;

export const deleteDepartment = async (lng = "english", id) => {
  try {
    const user = await getStorage();

    const options = {
      method: "DELETE",
      url: `/department`,
      data: {
        id,
      },
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    await apiInstance(options);

    toastMessage("success", language[lng].success_delete_department);
  } catch (error) {
    toastMessage("error", language[lng].error_delete_department);
  }
};

export const addDepartment = async (lng, department) => {
  try {
    let url = `/department`,
      user = await getStorage();

    let requestBody = {
      name: department,
      externalID: new Date().getTime(),
      institution: user.institution,
    };

    const options = {
      method: "POST",
      url,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
      data: requestBody,
    };

    return await apiInstance(options);
  } catch (error) {
    console.log("====================ADD DEPARTMENT ERROR================");
    console.log(error);
    console.log("====================================");
    toastMessage("error", language[lng].unable_add_department);
  }
};
