import React from "react";
import { connect } from "react-redux";
import language from "../../../language";
import { Table } from "../../Table";
import { Modal } from "../../Modal";
import serviceQuery from "../../../utils/queries/serviceQuery";
import handleSearch from "../../../utils/handleSearch";
import NewTranslation from "./NewTranslation";

let copyData = [];

class Translations extends React.Component {
  state = {
    isLoading: false,
    data: [],
    page: 1,
    limit: 10,
    search_text: "",
    selected_data: {},
    delete_password: "",
    error: {},
    csvData: [],
  };

  componentDidMount() {
    this.getData(true);
  }

  getData = async (isLoading) => {
    try {
      const { page, limit } = this.state;
      const { defaultLanguage, serviceId } = this.props;

      this.setState({ isLoading });

      const { data, count } = await serviceQuery(defaultLanguage, {
        page,
        limit,
        id: serviceId,
      });

      let result = data.map((el) => {
        return {
          name: el.name,
          language: "English",
        };
      });

      this.setState({
        data: result,
        totalPageCount: count,
        isLoading: false,
      });

      result = data?.slice(0) || [];
    } catch (error) {
      this.setState({
        isLoading: false,
      });
    }
  };

  onChangeText(field, e) {
    let { error } = this.state;

    delete error[field];

    this.setState({
      [field]: e.target.value,
      error,
    });
  }

  handleSearch(e) {
    this.setState({ search_text: e.target.value });
  }

  handlePagination(page) {
    this.setState(
      {
        page,
      },
      () => {
        this.getData(true);
      }
    );
  }

  handleShowModal(modal, modalTitle, selected_data = {}) {
    this.setState({
      [modal]: true,
      modalTitle: modalTitle,
      selected_data,
    });
  }

  handleCloseModal(modal) {
    this.setState({
      [modal]: false,
    });
  }

  returnTableHeaders() {
    let headers = [
      {
        title: language[this.props.defaultLanguage].text,
        key: "name",
      },
      {
        title: language[this.props.defaultLanguage].language,
        key: "language",
      },
    ];

    return headers;
  }

  onDelete() {
    this.setState({
      isDeleting: true,
    });
  }

  render() {
    const {
      edit,
      delete_,
      delete_translation,
      edit_translation,
      translation,
      add_translation,
    } = language[this.props.defaultLanguage];
    return (
      <>
        <Table
          data={this.state.data}
          page={this.state.page}
          limit={this.state.limit}
          totalPageCount={this.state.totalPageCount}
          isLoading={this.state.isLoading}
          handlePagination={this.handlePagination.bind(this)}
          headers={this.returnTableHeaders()}
          addButtonText={add_translation}
          actions={[
            {
              name: edit,
              onPress: (item) =>
                this.handleShowModal("showModal", edit_translation, item),
            },
            {
              name: delete_,
              onPress: this.handleShowModal.bind(
                this,
                "showDeleteModal",
                delete_translation
              ),
            },
          ]}
        />

        <Modal
          handleClose={this.handleCloseModal.bind(this, "showModal")}
          show={this.state.showModal}
          title={this.state.modalTitle}
          showHeaderBottomBorder={false}
        >
          <NewTranslation
            handleCloseModal={this.handleCloseModal.bind(this, "showModal")}
            getData={this.getData.bind(this)}
            category={this.props.category}
            {...this.state.selected_data}
          />
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(Translations);
