import { HorizontalBar } from "react-chartjs-2";
import { connect } from "react-redux";

const options = {
  maintainAspectRatio: false,
  animation: false,
  offset: true,
  scales: {
    y: {
      beginAtZero: true,
    },
  },
};
const BarChart = (props) => {
  return (
    <div className="chart-container mb-3 mt-3" style={{ height: 250 }}>
      <HorizontalBar data={props.data} options={options} />
    </div>
  );
};

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(BarChart);
