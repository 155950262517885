export default (csv) => {
  // Split CSV by line breaks
  const lines = csv.split("\n");

  // Extract headers
  const headers = lines[0].split(",");

  // Initialize array to hold parsed data
  const data = [];

  // Iterate over rows starting from the second row
  for (let i = 1; i < lines.length; i++) {
    const line = lines[i].trim();
    if (!line) continue; // Skip empty lines

    // Split row into values
    const values = line.split(",");

    // Create object with header-value pairs
    const entry = {};
    headers.forEach((header, index) => {
      entry[header.trim()] = values[index] ? values[index].trim() : ""; // Handle empty values
    });

    // Add object to data array
    data.push(entry);
  }

  return data;
};
