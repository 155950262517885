import "./styles.css";

import logoImage from "../../assets/logo.png";
import icons from "../../constants/icons";

const Logo = (props) => {
  return (
    <div className={`logo-container ${props.className}`}>
      <div
        className={`mobile-container ${
          props.type === "dashboard" ? "show" : ""
        }`}
        onClick={props.handleMenuIconClicked}
      >
        {props.toggleMenus ? (
          <div className="icon-container no-hover-bg">
            <span className={process.env.REACT_APP_ICONS_TYPE}>
              {icons.close}
            </span>
          </div>
        ) : (
          <div className="icon-container no-hover-bg">
            <span className={process.env.REACT_APP_ICONS_TYPE}>
              {icons.menu}
            </span>
          </div>
        )}
      </div>
      <div
        className="image-container"
        onClick={() => (window.location.href = "/landing")}
      >
        <img src={logoImage} alt="SHORA NEZA" />
      </div>
      <span
        className="logo-text"
        onClick={() => (window.location.href = "/landing")}
      >
        SHORA <span>NEZA</span>
      </span>
    </div>
  );
};

export default Logo;
