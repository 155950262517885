import { connect } from "react-redux";
import "./styles.css";
import { HorizontalLoader } from "../Loader";

import logoImage from "../../assets/logo.png";

const Splash = () => {
  return (
    <div className="splash-container">
      <center>
        <center className="splash-logo-container">
          <img src={logoImage} alt="MIFOTRA PSRP" />
          <div>
            SHORA <span>NEZA</span>
          </div>
        </center>
        <div style={{ marginTop: 15 }}>
          <HorizontalLoader />
        </div>
      </center>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(Splash);
