import React from "react";
import { Locations } from "../../components/Locations";
import { connect } from "react-redux";

class LocationsScreen extends React.Component {
  render() {
    return (
      <>
        <div className="card card-dashboard-content">
          <div className="card-body">
            <Locations />,
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(LocationsScreen);
