export default {
  home: "home",
  dashboard: "grid_view",
  request: "contract",
  service: "article",
  notification: "notifications",
  alert: "warning",
  department: "corporate_fare",
  users: "group",
  setting: "settings",
  request_service: "dvr",
  time_loading: "avg_pace",
  menu: "menu",
  user: "person",
  password: "lock",
  admin: "shield_person",
  passcode: "passkey",
  eye_on: "visibility",
  eye_off: "visibility_off",
  data_collector: "assignment_ind",
  identification: "badge",
  list: "article",
  email: "mail",
  phone: "phone",
  key: "key",
  search: "search",
  position: "person_apron",
  status: "sensors_krx",
  role: "settings_account_box",
  category: "filter_list",
  description: "edit_note",
  type: "table_rows",
  institution: "corporate_fare",
  time: "schedule",
  delete: "delete",
  remove: "remove",
  add: "add",
  calendar: "calendar_month",
  close: "close",
  chevronDown: "expand_more",
  refresh: "refresh",
  arrowRightAlt: "arrow_right_alt",
  arrowLeft: "keyboard_backspace",
  chevronRight: "chevron_right",
  chevronUp: "expand_less",
  level: "filter_list",
  download: "download",
  done: "done",
  waiting: "avg_pace",
  article: "article",
  check: "check",
  fullscreen: "fullscreen",
  filter: "filter_alt",
  upload: "upload",
  photo: "photo_camera",
  admin_user: "admin_panel_settings",
  signature: "signature",
  success: "check",
  fail: "close",
  answerType: "border_color",
  comment: "forum",
  send: "send",
  table: "table",
  attach_file_add: "attach_file_add",
  duplicate: "content_copy",
  reset: "restart_alt",
  videoCircle: "play_circle",
  reports: "view_list",
  projects: "contract",
  candidates: "users",
  location: "location_on",
  communication: "mail",
  surveys: "ballot",
  map: "map",
  wallet: "wallet",
  payments: "payments",
  forum: "forum",
  save: "save",
  filter: "filter_alt",
  user: "person",
};
