import React from "react";
import { Tabs } from "../../components/Tabs";
import { connect } from "react-redux";
import language from "../../language";
import { DashboardHeader } from "../../components/Header";
import { MyProfile, ChangePassword } from "../../components/Profile";
import { TwoFactorAuthentication } from "../../components/Security";

class SettingScreen extends React.Component {
  render() {
    const { my_profile, change_password, settings, setting_description } =
      language[this.props.defaultLanguage];
    return (
      <>
        <DashboardHeader title={settings} description={setting_description} />
        <div className="card card-dashboard-content">
          <div className="card-body">
            <Tabs
              options={[
                { title: my_profile, data: <MyProfile /> },
                {
                  title:
                    language[this.props.defaultLanguage].two_factor_tab_name,
                  data: (
                    <>
                      <TwoFactorAuthentication />
                    </>
                  ),
                },
                { title: change_password, data: <ChangePassword /> },
              ]}
            />
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(SettingScreen);
