import { connect } from "react-redux";
import language from "../../../language";
import "./styles.css";
import { Button } from "../../Button";
import icons from "../../../constants/icons";

const LeftContent = (props) => {
  return (
    <div
      className="col-xs-12 col-sm-12 col-md-5"
      style={{ marginRight: "4rem" }}
    >
      <div className="intro-text-container">
        <h1>
          {props.serviceTypeName ? (
            props.serviceTypeName
          ) : (
            <>{language[props.defaultLanguage].intro_title_01}</>
          )}
        </h1>
        <p>{language[props.defaultLanguage].intro_description}</p>
        <div className="intro-button-container">
          <Button
            text={language[props.defaultLanguage].login}
            styles={{ minWidth: 100 }}
            onPress={() => (window.location.href = "/admin-login")}
          />
          <Button
            className="btn-transparent light"
            text={language[props.defaultLanguage].how_works}
            styles={{ minWidth: 100 }}
            icon={icons.videoCircle}
          />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(LeftContent);
