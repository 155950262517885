import React from "react";
import "./styles.css";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { withRouter } from "../../utils/withRouter";
import { Logo } from "../Logo";
import icons from "../../constants/icons";
import NotificationBadgeCount from "../Notifications/NotificationBadgeCount";
import { onResetFilters } from "../../actions/Filters";

class Sidebar extends React.Component {
  constructor() {
    super();

    this.mobileNavbarRef = React.createRef();

    this.setState({
      menus: [],
    });
  }

  checkMobileSize() {
    return !window.matchMedia("only screen and (min-width: 1200px)").matches;
  }

  componentDidMount = async () => {
    this.setState({
      menus: this.props.menus,
    });

    const isMobile = this.checkMobileSize();

    if (isMobile) {
      this.handleHideSidebar();
    }

    window.addEventListener("resize", this.handleResize.bind(this));
  };

  handleShowSidebar() {
    const container = document.getElementById("sidebar-container");
    if (container && container?.classList) {
      let nav_class_list = container.classList;
      if (container && nav_class_list.contains("hide")) {
        nav_class_list.add("show");
        nav_class_list.remove("hide");
      }
    }
  }

  handleHideSidebar() {
    const container = document.getElementById("sidebar-container");

    if (container && container?.classList) {
      let nav_class_list = container.classList;

      if (container && nav_class_list.contains("show")) {
        nav_class_list.add("hide");
        nav_class_list.remove("show");
      }
    }
  }

  handleClickSidenav(e) {
    e.preventDefault();

    const isMobile = this.checkMobileSize();

    if (isMobile) {
      this.handleHideSidebar();
    }
  }

  handleToggleSubMenus(menuIndex) {
    let { menus } = this.props;
    menus[menuIndex].submenusVisibility = !menus[menuIndex].submenusVisibility;

    this.setState({ menus });
  }

  handleResize() {
    const { innerWidth } = window;

    if (innerWidth > 1200) {
      this.handleShowSidebar();
    } else {
      this.handleHideSidebar();
    }
  }

  handleResetFilters() {
    this.props.dispatch(onResetFilters("service"));
    this.props.dispatch(onResetFilters("location"));
    this.props.dispatch(onResetFilters("questionText"));
    this.props.dispatch(onResetFilters("answer"));
  }

  render() {
    return (
      <div
        className="sidebar-container show"
        id="sidebar-container"
        ref={this.mobileNavbarRef}
        onClick={this.handleClickSidenav.bind(this)}
      >
        <div className="mobile-sidebar-header-container hidden-sm hidden-xs">
          <div style={{ flex: 1 }}>
            <Logo handleMenuIconClicked={this.handleHideSidebar.bind(this)} />
          </div>
          <div className="icon-container">
            <span className={process.env.REACT_APP_ICONS_TYPE}>
              {icons.close}
            </span>
          </div>
        </div>
        <ul>
          {this.props?.menus?.map((el, i) => {
            if (!el) return;

            let splitUrlRoutePath = this.props.routeParams["*"].split("/");

            if (!el.path) return;

            return (
              <li key={i}>
                {el.menus ? (
                  <Link
                    to={!el.menus ? el.parentPath + "" + el.path : "#"}
                    className={`${
                      splitUrlRoutePath.includes(el?.name?.toLowerCase()) ||
                      splitUrlRoutePath.includes(el.key)
                        ? "active"
                        : ""
                    }`}
                    onClick={() =>
                      el.menus ? this.handleToggleSubMenus(i) : null
                    }
                  >
                    <div className="menu-icon-container">
                      <span className={process.env.REACT_APP_ICONS_TYPE}>
                        {el.icon}
                      </span>
                    </div>
                    <div className="name-container">
                      <span style={{ flex: 1 }}>{el.name}</span>
                      {el.showNotification &&
                        !splitUrlRoutePath.includes(el.name.toLowerCase()) &&
                        el?.notificationStorageName &&
                        this.props[el.notificationStorageName] &&
                        this.props[el.notificationStorageName]?.length > 0 && (
                          <NotificationBadgeCount
                            count={
                              this.props[el.notificationStorageName]?.length
                            }
                          />
                        )}
                    </div>
                    {el.menus && el.menus.length > 0 && (
                      <div
                        className="toggle-container"
                        onClick={() => this.handleToggleSubMenus(i)}
                      >
                        <span className={process.env.REACT_APP_ICONS_TYPE}>
                          {el.submenusVisibility
                            ? icons.chevronUp
                            : icons.chevronDown}
                        </span>
                      </div>
                    )}
                  </Link>
                ) : (
                  <Link
                    to={!el.menus ? el.parentPath + "" + el.path : "#"}
                    className={`${
                      splitUrlRoutePath.includes(el?.name?.toLowerCase()) ||
                      splitUrlRoutePath.includes(el.key)
                        ? "active"
                        : ""
                    }`}
                    onClick={() => this.handleResetFilters()}
                  >
                    <div className="menu-icon-container">
                      <span className={process.env.REACT_APP_ICONS_TYPE}>
                        {el.icon}
                      </span>
                    </div>
                    <div className="name-container">
                      <span style={{ flex: 1 }}>{el.name}</span>
                      {el.showNotification &&
                        !splitUrlRoutePath.includes(el.name.toLowerCase()) &&
                        el?.notificationStorageName &&
                        this.props[el.notificationStorageName] &&
                        this.props[el.notificationStorageName]?.length > 0 && (
                          <NotificationBadgeCount
                            count={
                              this.props[el.notificationStorageName]?.length
                            }
                          />
                        )}
                    </div>
                    {el.menus && el.menus.length > 0 && (
                      <div
                        className="toggle-container"
                        onClick={() => this.handleToggleSubMenus(i)}
                      >
                        <span className={process.env.REACT_APP_ICONS_TYPE}>
                          {el.submenusVisibility
                            ? icons.chevronUp
                            : icons.chevronDown}
                        </span>
                      </div>
                    )}
                  </Link>
                )}
                {!el.submenusVisibility &&
                  el.menus &&
                  el.menus.length > 0 &&
                  el.menus.map((subM, m) => {
                    return (
                      <ul key={m} className="nested">
                        <li>
                          <Link
                            to={el.parentPath + "" + el.path + "" + subM.path}
                            className={`${
                              splitUrlRoutePath.includes(
                                subM.name.toLowerCase()
                              ) || splitUrlRoutePath.includes(subM.key)
                                ? "active"
                                : ""
                            }`}
                            onClick={() => this.handleResetFilters()}
                          >
                            <div style={{ flex: 1 }}>
                              {subM.menus?.length > 0 ? (
                                <b>{subM.name}</b>
                              ) : (
                                subM.name
                              )}
                            </div>
                            {subM.showNotification &&
                              subM.type &&
                              this.props.requestCounts[subM.type] > 0 &&
                              this.props.requestCounts[subM.type] > 0 && (
                                <NotificationBadgeCount
                                  count={this.props.requestCounts[subM.type]}
                                />
                              )}
                          </Link>

                          {subM.menus &&
                            subM.menus.length > 0 &&
                            subM.menus.map((subMM, mm) => {
                              return (
                                <ul
                                  key={mm}
                                  className="nested"
                                  style={{ margin: "0 10px" }}
                                >
                                  <li>
                                    <Link
                                      to={
                                        el.parentPath +
                                        "" +
                                        el.path +
                                        "" +
                                        subM.path +
                                        "" +
                                        subMM.path
                                      }
                                      className={`${
                                        splitUrlRoutePath.includes(
                                          subMM.name.toLowerCase()
                                        )
                                          ? "active"
                                          : ""
                                      }`}
                                      onClick={() => this.handleResetFilters()}
                                    >
                                      <div style={{ flex: 1 }}>
                                        {subMM.name}
                                      </div>
                                    </Link>
                                  </li>
                                </ul>
                              );
                            })}
                        </li>
                      </ul>
                    );
                  })}
              </li>
            );
          })}
        </ul>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  const { notifications } = state.Notifications;
  const { menus } = state.Sidebar;
  return {
    defaultLanguage,
    notifications,
    menus,
  };
};

export default connect(mapStateToProps)(withRouter(Sidebar));
