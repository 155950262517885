export default [
  {
    label: "User",
    value: "user",
  },
  {
    label: "Admin",
    value: "Admin",
  },
];
