// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.list-candidate-performance-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 60px;
}

.regions-counts .filters-header-container {
  display: flex;
  align-items: center;
}

.regions-counts .card-header {
  display: flex;
  align-items: center;
}

.regions-counts .filters-header-container .icon-container span {
  font-size: 20px;
  opacity: 0.8;
}

.regions-counts .card-header .title {
  flex: 1 1;
}

.regions-counts .filters-header-container .filter-btn {
  background-color: var(--text-color-10);
  display: flex;
  align-items: center;
  border-radius: 16px;
  cursor: pointer;
  opacity: 0.8;
  padding: 0.3rem 0.6rem;
}

.regions-counts .filters-header-container .filter-btn:hover {
  opacity: 1;
}

.chart-header {
  border: 1px solid var(--text-color-5);
  min-height: 50px;
  display: flex;
  align-items: center;
  padding: 1rem;
}
`, "",{"version":3,"sources":["webpack://./src/app/components/HomeAnalytics/styles.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,YAAY;AACd;;AAEA;EACE,SAAO;AACT;;AAEA;EACE,sCAAsC;EACtC,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,eAAe;EACf,YAAY;EACZ,sBAAsB;AACxB;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,qCAAqC;EACrC,gBAAgB;EAChB,aAAa;EACb,mBAAmB;EACnB,aAAa;AACf","sourcesContent":[".list-candidate-performance-item {\r\n  display: flex;\r\n  align-items: center;\r\n  justify-content: space-between;\r\n  min-height: 60px;\r\n}\r\n\r\n.regions-counts .filters-header-container {\r\n  display: flex;\r\n  align-items: center;\r\n}\r\n\r\n.regions-counts .card-header {\r\n  display: flex;\r\n  align-items: center;\r\n}\r\n\r\n.regions-counts .filters-header-container .icon-container span {\r\n  font-size: 20px;\r\n  opacity: 0.8;\r\n}\r\n\r\n.regions-counts .card-header .title {\r\n  flex: 1;\r\n}\r\n\r\n.regions-counts .filters-header-container .filter-btn {\r\n  background-color: var(--text-color-10);\r\n  display: flex;\r\n  align-items: center;\r\n  border-radius: 16px;\r\n  cursor: pointer;\r\n  opacity: 0.8;\r\n  padding: 0.3rem 0.6rem;\r\n}\r\n\r\n.regions-counts .filters-header-container .filter-btn:hover {\r\n  opacity: 1;\r\n}\r\n\r\n.chart-header {\r\n  border: 1px solid var(--text-color-5);\r\n  min-height: 50px;\r\n  display: flex;\r\n  align-items: center;\r\n  padding: 1rem;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
