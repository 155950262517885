import React from "react";
import { connect } from "react-redux";
import "./styles.css";
import icons from "../../constants/icons";
import language from "../../language";
import { FileUploadButton } from "../Button";
import pdfImg from "../../assets/pdf.png";
import docsImg from "../../assets/docs.png";
import photoImg from "../../assets/image.png";
class RequiredDocuments extends React.Component {
  state = {
    isRetrievingUserInfo: false,
    profileData: {},
  };

  render() {
    return (
      <div className="request-service-document">
        <div className="card-header">
          <h3 className="text-primary">
            {language[this.props.defaultLanguage].required_documents}
          </h3>
        </div>

        <div className="card-body">
          <div className="row">
            {this.props?.files?.map((el, i) => {
              const ext =
                el.name.substr(el.name.lastIndexOf(".") + 1)?.toLowerCase() ||
                "";

              return (
                <div className={`col-md-12`} key={i}>
                  <div
                    className={`document-item  ${
                      this.props.error[el.name] ? "document-error" : ""
                    }`}
                  >
                    {/* <div className="count-container">
                      <span>{i + 1}</span>
                    </div> */}
                    <div className="document-name">
                      <p className="mb-2">{el.name.split(".")[0]}</p>
                      <p className="text-primary text-bold">
                        <small>{`Only ${ext?.toUpperCase()} allowed`}</small>
                      </p>
                      {this.props.error[el.name] && (
                        <span className="text-error mt-2 text-bold">
                          {this.props.error[el.name]}
                        </span>
                      )}
                      {el?.uploadedFiles?.length > 0 && (
                        <div className="uploaded-file-container">
                          {el.uploadedFiles.map((el, fileIndex) => {
                            const extSplit = el.type.split("/");
                            const extension = extSplit[extSplit.length - 1];

                            return (
                              <div className="file-container" key={fileIndex}>
                                <div className="extension">
                                  <img
                                    src={
                                      extension === "pdf"
                                        ? pdfImg
                                        : extension === "docs"
                                        ? docsImg
                                        : photoImg
                                    }
                                  />
                                </div>
                                <div style={{ flex: 1 }}>
                                  <span className="name">{el.name}</span>
                                </div>
                                <div
                                  className="icon-container"
                                  onClick={() =>
                                    this.props.handleRemoveFile({
                                      parentIndex: i,
                                      fileIndex,
                                    })
                                  }
                                >
                                  <span
                                    className={process.env.REACT_APP_ICONS_TYPE}
                                  >
                                    {icons.delete}
                                  </span>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      )}
                    </div>
                    {
                      <div className="button-container">
                        <FileUploadButton
                          className="btn-sm btn-bordered btn-transparent" // btn-transparent primary bordered
                          text="Upload"
                          icon={icons.add}
                          handleFiles={(file) =>
                            this.props.handleFiles(i, file)
                          }
                          accept={`${
                            ["png", "jpg", "jpeg"].includes(ext)
                              ? "image/*"
                              : "application/" + ext
                          }`}
                        />
                      </div>
                    }
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(RequiredDocuments);
