import React from "react";
import { connect } from "react-redux";
import "../styles.css";
import { Input, Select } from "../../Input";
import language from "../../../language";
import { Button } from "../../Button";
import surveysQuery from "../../../utils/queries/surveysQuery";
import dynamicTableData from "../../../utils/dynamicTableData";
import { Table } from "../../Table";
import {
  generateRandomNumber,
  generateRandomString,
} from "../../../utils/random";

class FormSearch extends React.Component {
  state = {
    categories: [],
    uniqueNumber: "",
    surveys: [],
    error: {},
    result: [],
  };

  onChangeText(field, e) {
    let { error } = this.state;

    delete error[field];

    this.setState({
      [field]: e.target ? e.target.value : e,
    });
  }

  onSearch = async (isSearching) => {
    try {
      let { page, limit, uniqueNumber, error } = this.state;
      const { defaultLanguage } = this.props;

      if (uniqueNumber === "") {
        error.uniqueNumber =
          language[this.props.defaultLanguage].unique_number_required;

        return this.setState({ error });
      }

      this.setState({ isSearching });

      const { data } = await surveysQuery(defaultLanguage, {
        page,
        limit,
        ["questionnaireAnswer.answer"]: uniqueNumber,
      });

      const { tableHeaders, rowData } = dynamicTableData(this, data);

      this.setState(
        {
          isSearching: false,
          result: rowData,
          tableHeaders,
        },
        () => {
          this.props.handleGenerateNumber &&
            this.props.handleGenerateNumber(uniqueNumber.toUpperCase());
        }
      );
    } catch (error) {
      this.setState({
        isLoading: false,
      });
    }
  };

  handleGenerateNumber() {
    const randomString = generateRandomString(2);
    const randomNumbers = Array.from({ length: 6 }, () =>
      generateRandomNumber(0, 9)
    ).join("");
    const n = `${randomString.toUpperCase()}${randomNumbers}`;

    this.props.handleGenerateNumber &&
      this.props.handleGenerateNumber(n.toUpperCase());

    this.setState({
      uniqueNumber: n,
    });

    return n;
  }

  render() {
    return (
      <div className="card">
        <div className="card-body">
          <div className="row">
            <div className="col-md-4">
              <Input
                options={this.state.categories}
                label={language[this.props.defaultLanguage].unique_number}
                placeholder={
                  language[this.props.defaultLanguage].unique_number_placeholder
                }
                value={this.state.uniqueNumber}
                onChange={(e) => this.onChangeText("uniqueNumber", e)}
                error={this.state.error.uniqueNumber}
              />
            </div>
            {this.state.isSearch && (
              <div className="col-md-4">
                <Select
                  options={this.state.surveys}
                  label={language[this.props.defaultLanguage].survey}
                  value={this.state.survey}
                  onChange={(e) => this.onChangeText("survey", e)}
                  error={this.state.error.survey}
                />
              </div>
            )}
            <div className="d-flex gap-3">
              <Button
                className="btn-default"
                text={
                  language[this.props.defaultLanguage].generate_unique_number
                }
                isSubmitting={this.state.isGenerating}
                onPress={this.handleGenerateNumber.bind(this)}
              />
              <Button
                text={language[this.props.defaultLanguage].search}
                isSubmitting={this.state.isSearching}
                onPress={this.onSearch.bind(this)}
              />
            </div>
          </div>
        </div>

        {this.state.result.length > 0 && (
          <div className="card-body">
            <Table data={this.state.result} headers={this.state.tableHeaders} />
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(FormSearch);
