// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nav-tabs {
  z-index: 100 !important;
  margin-bottom: 1rem !important;
}
.tab {
  border-bottom: 1px solid var(--text-color-10);
  margin-top: 0;
  display: flex;
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.tab::-webkit-scrollbar {
  display: none;
}

.tab .nav-item button,
.tab .nav-item {
  font-weight: 700;
  color: var(--text-color);
}

.nav-tabs .nav-link {
  opacity: 0.8;
  border: 0;
  border-radius: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  vertical-align: middle;
  font-size: var(--font-size);
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  border: 0;
  border-radius: 0;
}

.nav-tabs .nav-link.active {
  opacity: 1;
  border-bottom: 3px solid var(--primary-color);
  background-color: transparent;
  color: var(--primary-color);
}
`, "",{"version":3,"sources":["webpack://./src/app/components/Tabs/style.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,8BAA8B;AAChC;AACA;EACE,6CAA6C;EAC7C,aAAa;EACb,aAAa;EACb,WAAW;EACX,gBAAgB;EAChB,kBAAkB;EAClB,wBAAwB,EAAE,gBAAgB;EAC1C,qBAAqB,EAAE,YAAY;AACrC;;AAEA;EACE,aAAa;AACf;;AAEA;;EAEE,gBAAgB;EAChB,wBAAwB;AAC1B;;AAEA;EACE,YAAY;EACZ,SAAS;EACT,gBAAgB;EAChB,mBAAmB;EACnB,uBAAuB;EACvB,sBAAsB;EACtB,2BAA2B;AAC7B;;AAEA;;EAEE,SAAS;EACT,gBAAgB;AAClB;;AAEA;EACE,UAAU;EACV,6CAA6C;EAC7C,6BAA6B;EAC7B,2BAA2B;AAC7B","sourcesContent":[".nav-tabs {\r\n  z-index: 100 !important;\r\n  margin-bottom: 1rem !important;\r\n}\r\n.tab {\r\n  border-bottom: 1px solid var(--text-color-10);\r\n  margin-top: 0;\r\n  display: flex;\r\n  width: 100%;\r\n  overflow-x: auto;\r\n  overflow-y: hidden;\r\n  -ms-overflow-style: none; /* IE and Edge */\r\n  scrollbar-width: none; /* Firefox */\r\n}\r\n\r\n.tab::-webkit-scrollbar {\r\n  display: none;\r\n}\r\n\r\n.tab .nav-item button,\r\n.tab .nav-item {\r\n  font-weight: 700;\r\n  color: var(--text-color);\r\n}\r\n\r\n.nav-tabs .nav-link {\r\n  opacity: 0.8;\r\n  border: 0;\r\n  border-radius: 0;\r\n  white-space: nowrap;\r\n  text-overflow: ellipsis;\r\n  vertical-align: middle;\r\n  font-size: var(--font-size);\r\n}\r\n\r\n.nav-tabs .nav-item.show .nav-link,\r\n.nav-tabs .nav-link.active {\r\n  border: 0;\r\n  border-radius: 0;\r\n}\r\n\r\n.nav-tabs .nav-link.active {\r\n  opacity: 1;\r\n  border-bottom: 3px solid var(--primary-color);\r\n  background-color: transparent;\r\n  color: var(--primary-color);\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
