import "./styles.css";

const SlideItem = (props) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div className="slide-item-container">
        <div className="slide-item-image">
          <img src={props.image} />
        </div>
        <div className="slide-item-content">
          <h3>{props.title}</h3>
          <p>{props.description}</p>
        </div>
      </div>
    </div>
  );
};

export default SlideItem;
