// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 24px;
  min-height: 24px;
  border-radius: var(--border-radius-half);
  background-color: transparent;
  transition: background-color 0.4s;
  cursor: pointer;
}

.icon-container.active {
  background-color: var(--primary-color);
}

.icon-container.active span {
  color: var(--light-color);
}

.icon-container.gray {
  background-color: var(--text-color-10);
}

.icon-container span {
  font-size: var(--font-size-md-xlg);
  color: var(--text-color);
}

.icon-container.sm span {
  font-size: var(--font-size-md);
}

.icon-container:hover {
  background-color: var(--text-color-10);
}

.icon-container.no-hover-bg:hover {
  background-color: transparent;
}

.material-symbols-outlined {
  color: var(--text-color-60);
  font-size: 20px;
}

.icon-primary-color {
  color: var(--primary-color);
}
`, "",{"version":3,"sources":["webpack://./src/app/common/styles/icons.styles.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,eAAe;EACf,gBAAgB;EAChB,wCAAwC;EACxC,6BAA6B;EAC7B,iCAAiC;EACjC,eAAe;AACjB;;AAEA;EACE,sCAAsC;AACxC;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,sCAAsC;AACxC;;AAEA;EACE,kCAAkC;EAClC,wBAAwB;AAC1B;;AAEA;EACE,8BAA8B;AAChC;;AAEA;EACE,sCAAsC;AACxC;;AAEA;EACE,6BAA6B;AAC/B;;AAEA;EACE,2BAA2B;EAC3B,eAAe;AACjB;;AAEA;EACE,2BAA2B;AAC7B","sourcesContent":[".icon-container {\r\n  display: flex;\r\n  align-items: center;\r\n  justify-content: center;\r\n  min-width: 24px;\r\n  min-height: 24px;\r\n  border-radius: var(--border-radius-half);\r\n  background-color: transparent;\r\n  transition: background-color 0.4s;\r\n  cursor: pointer;\r\n}\r\n\r\n.icon-container.active {\r\n  background-color: var(--primary-color);\r\n}\r\n\r\n.icon-container.active span {\r\n  color: var(--light-color);\r\n}\r\n\r\n.icon-container.gray {\r\n  background-color: var(--text-color-10);\r\n}\r\n\r\n.icon-container span {\r\n  font-size: var(--font-size-md-xlg);\r\n  color: var(--text-color);\r\n}\r\n\r\n.icon-container.sm span {\r\n  font-size: var(--font-size-md);\r\n}\r\n\r\n.icon-container:hover {\r\n  background-color: var(--text-color-10);\r\n}\r\n\r\n.icon-container.no-hover-bg:hover {\r\n  background-color: transparent;\r\n}\r\n\r\n.material-symbols-outlined {\r\n  color: var(--text-color-60);\r\n  font-size: 20px;\r\n}\r\n\r\n.icon-primary-color {\r\n  color: var(--primary-color);\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
