import React from "react";
import { connect } from "react-redux";
import language from "../../../language";
import { DraggableTable, Tabl } from "../../Table";
import { DeleteModal, Modal } from "../../Modal";
import NewQuestionnaire from "./NewQuestionnaire";
import serviceQuery from "../../../utils/queries/serviceQuery";
import handleSearch from "../../../utils/handleSearch";
import toastMessage from "../../../utils/toastMessage";
import { getStorage } from "../../../utils/storage";
import icons from "../../../constants/icons";
import formatSelectData from "../../../utils/formatSelectData";
import { apiInstance } from "../../../utils/axiosInstance";
import { Input } from "../../Input";
import { Button } from "../../Button";
import { CSVLink } from "react-csv";

let copyData = [];

class Questionnaires extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      data: [],
      page: 1,
      limit: 100,
      search_text: "",
      selected_data: {},
      delete_password: "",
      error: {},
      csvData: [],
      user: {},
      questionnaire: [],
      headerFilters: [],
    };

    this.tableRef = React.createRef();
  }

  componentDidMount = async () => {
    const user = await getStorage();

    this.setState({ user });
    this.getData(true);
  };

  getData = async (isLoading) => {
    try {
      const { page, limit } = this.state;
      const { defaultLanguage, serviceId } = this.props;

      this.setState({ isLoading });

      const { data, count } = await serviceQuery(defaultLanguage, {
        page,
        limit,
        id: serviceId,
      });

      let questionnaire = [];

      if (data.length > 0) {
        questionnaire = data[0].questionnaire;
      }

      this.setState({
        data: data.length > 0 ? data[0] : {},
        questionnaire,
        totalPageCount: count,
        isLoading: false,
      });

      copyData = questionnaire?.slice(0) || [];
    } catch (error) {
      this.setState({
        isLoading: false,
      });
    }
  };

  onChangeText(field, e) {
    let { error } = this.state;

    delete error[field];

    this.setState({
      [field]: e.target.value,
      error,
    });
  }

  handlePagination(page) {
    this.setState(
      {
        page,
      },
      () => {
        this.getData(true);
      }
    );
  }

  handleShowModal(modal, modalTitle, selected_data = {}) {
    console.log("selected_data", selected_data);

    this.setState({
      [modal]: true,
      modalTitle: modalTitle,
      selected_data,
    });
  }

  handleCloseModal(modal) {
    this.setState({
      [modal]: false,
    });
  }

  returnTableHeaders() {
    let headers = [
      {
        title: language[this.props.defaultLanguage].question,
        key: "questionText",
      },
      {
        title: language[this.props.defaultLanguage].answer_type,
        key: "answerType",
      },
      {
        title: "",
        key: "multiple-actions",
        actions: [
          {
            icon: icons.eye_on,
            title: language[this.props.defaultLanguage].edit,
            onPress: (item, dataIndex) =>
              this.handleShowModal("showModal", item.question, {
                ...item,
                dataIndex,
              }),
          },
          {
            icon: icons.delete,
            className: "text-danger",
            showNotificationCount: true,
            user: this.state.user.id,
            onPress: (item, dataIndex) =>
              this.handleShowModal("showDeleteModal", item.question, {
                ...item,
                dataIndex,
              }),
          },
        ],
      },
    ];

    return headers;
  }

  onDelete = async () => {
    try {
      this.setState({ isDeleting: true });

      let { selected_data, data } = this.state;
      let { questionnaire } = data;
      let user = await getStorage();

      questionnaire.splice(selected_data.dataIndex, 1);

      this.setState({ data: this.state.data });

      const options = {
        method: "PUT",
        url: `/service`,
        data: {
          id: data._id,
          questionnaire,
        },
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      };

      await apiInstance(options);

      toastMessage(
        "success",
        language[this.props.defaultLanguage].delete_questionnaire_success
      );

      this.setState({ isDeleting: false });

      this.handleCloseModal("showDeleteModal");
      this.getData();
    } catch (error) {
      this.setState({
        isDeleting: false,
      });

      toastMessage(
        "error",
        language[this.props.defaultLanguage].error_delete_questionnaire
      );
    }
  };

  handleDrag() {}

  handleStart() {
    console.log("drag started here....");
  }

  onDragEnd(e) {
    const { destination, source } = e;
    let { data } = this.state;
    const { questionnaire } = data;

    const destinationIndex = destination.index;
    const sourceIndex = source.index;

    // Remove the item from its current position
    let item = this.state?.data?.questionnaire.splice(sourceIndex, 1)[0];

    // Insert the item into the new position
    questionnaire.splice(destinationIndex, 0, item);

    this.setState({ data }, () => {
      this.handleShowSaveButton();
    });
  }

  handleShowSaveButton() {
    let { headerFilters } = this.state;

    let buttonData = {
      title: language[this.props.defaultLanguage].save_changes,
      button_type: "button",
      icon: icons.save,
      type: "dragEnd",
      onPress: () => this.updateQuestionnaire(),
      isLoading: this.state.isUpdating,
      className: "btn-default",
    };

    if (headerFilters.length === 0) {
      headerFilters.push(buttonData);
    } else {
      headerFilters.forEach((el) => {
        if (!el.type === "dragEnd") {
          headerFilters.push(buttonData);
        }
      });
    }

    this.setState({ headerFilters });
  }

  updateQuestionnaire() {
    const { data, user } = this.state;

    this.setState({
      isUpdating: true,
    });

    const options = {
      url: "/service",
      method: "PUT",
      data: {
        id: data._id,
        questionnaire: data.questionnaire,
      },
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    apiInstance(options)
      .then((data) => {
        this.setState({
          isUpdating: false,
        });

        toastMessage(
          "success",
          language[this.props.defaultLanguage].data_update_success
        );
      })
      .catch((error) => {
        this.setState({
          isUpdating: false,
        });

        toastMessage(
          "error",
          language[this.props.defaultLanguage].error_update_failed
        );
      });
  }

  downloadExcel = async () => {
    try {
      this.setState({
        isExporting: true,
      });

      const { page, limit } = this.state;
      const { defaultLanguage, serviceId } = this.props;

      const { data } = await serviceQuery(defaultLanguage, {
        page,
        limit,
        id: serviceId,
      });

      let questionnaire = [];

      for (let dataItem of data) {
        for (let el of dataItem.questionnaire) {
          questionnaire.push(el.questionText);
        }
      }

      this.setState(
        {
          isExporting: false,
          csvData: [questionnaire],
        },
        () => {
          this.refs.csvDownload?.link.click();

          this.handleCloseModal("showExport");
        }
      );
    } catch (error) {
      this.setState({
        isExporting: false,
      });
      toastMessage("error", error);
    }
  };

  render() {
    const { add_questionnaire, edit_questionnaire, questionnaire } =
      language[this.props.defaultLanguage];

    return (
      <>
        <DraggableTable
          data={this.state.data?.questionnaire || []}
          isSearch
          page={this.state.page}
          limit={this.state.limit}
          search_text={this.state.search_text}
          handleSearch={(e) =>
            handleSearch(this, {
              copyData,
              value: e.target.value,
            })
          }
          totalPageCount={this.state.totalPageCount}
          isLoading={this.state.isLoading}
          handlePagination={this.handlePagination.bind(this)}
          headers={this.returnTableHeaders()}
          showAdd
          addButtonText={add_questionnaire}
          draggable
          rowPress={(item, dataIndex) =>
            this.handleShowModal("showModal", edit_questionnaire, {
              ...item,
              dataIndex,
            })
          }
          handleAddPressed={this.handleShowModal.bind(
            this,
            "showModal",
            questionnaire
          )}
          handleDrag={this.handleDrag.bind(this)}
          handleStart={this.handleStart.bind(this)}
          onDragEnd={this.onDragEnd.bind(this)}
          filters={[
            {
              type: "import",
              title: language[this.props.defaultLanguage].template,
              button_type: "dropdown",
              icon: "download",
              options: [
                {
                  name: "CSV",
                  onPress: () =>
                    this.handleShowModal("showExport", "Export Template CSV"),
                },
              ],
            },
            ...this.state.headerFilters,
          ]}
        />

        <Modal
          handleClose={this.handleCloseModal.bind(this, "showModal")}
          show={this.state.showModal}
          title={this.state.modalTitle}
          showHeaderBottomBorder={false}
          size="lg"
        >
          <NewQuestionnaire
            handleCloseModal={this.handleCloseModal.bind(this, "showModal")}
            getData={this.getData.bind(this)}
            serviceData={this.state.data}
            selectedData={this.state.selected_data}
            questionnaires={formatSelectData(
              this.state?.data?.questionnaire || [],
              "questionText",
              "_id"
            )}
          />
        </Modal>

        <Modal
          handleClose={this.handleCloseModal.bind(this, "showExport")}
          show={this.state.showExport}
          title={this.state.modalTitle}
        >
          <div className="card-body">
            <Input
              label={language[this.props.defaultLanguage].limit}
              type="number"
              value={this.state.limit}
              onChange={(e) => this.onChangeText("limit", e)}
            />
            <Button
              text={language[this.props.defaultLanguage].export_}
              isSubmitting={this.state.isExporting}
              onPress={this.downloadExcel.bind(this)}
            />
          </div>
        </Modal>

        <Modal
          handleClose={this.handleCloseModal.bind(this, "showDeleteModal")}
          show={this.state.showDeleteModal}
          title={this.state.modalTitle}
        >
          <DeleteModal
            handleCloseModal={this.handleCloseModal.bind(
              this,
              "showDeleteModal"
            )}
            error={this.state.error.delete_password}
            value={this.state.delete_value}
            onDelete={this.onDelete.bind(this)}
            onChange={(e) => this.onChangeText("delete_value", e)}
            isDeleting={this.state.isDeleting}
            name={this.state.selected_data.questionText}
            getData={this.getData.bind(this)}
          />
        </Modal>
        <CSVLink
          ref="csvDownload"
          filename={this.props.serviceName + " template" + new Date().getTime()}
          data={this.state.csvData}
        ></CSVLink>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(Questionnaires);
