import toastMessage from "../toastMessage";
import formatSelectData from "../formatSelectData";
import { getStorage } from "../storage";
import language from "../../language";
import { apiInstance } from "../axiosInstance";

const fetchData = async (lng = "english", params) => {
  try {
    const {
      page,
      limit,
      type,
      category,
      user,
      id,
      requestType,
      startCreatedAt,
      endCreatedAt,
    } = params;

    const userLoggedIn = await getStorage();
    const { token, role } = userLoggedIn;

    let requestBody = {
      ...params,
      page: page || 1,
      limit: limit || 10,
      category,
      user,
      id,
      type: requestType,
      startCreatedAt,
      endCreatedAt,
    };

    if (role === "user") {
      requestBody.user = [userLoggedIn.id];
    }

    let url =
      requestBody["rank"] || requestBody["order"]
        ? "/survey/analytics"
        : "/survey";
    const options = {
      method: "GET",
      url: url,
      params: requestBody,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const queryRes = await apiInstance(options);

    let { data, count } = queryRes.data;

    if (type === "dropdown") {
      data = formatSelectData(data, "description", "_id");
    }

    return { data, count };
  } catch (error) {
    toastMessage("error", language[lng].unable_fetch_surveys);
  }
};

export const deleteSurvey = async (lng = "english", id) => {
  try {
    const user = await getStorage();

    const options = {
      method: "DELETE",
      url: `/survey`,
      data: {
        id,
      },
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    await apiInstance(options);

    toastMessage("success", language[lng].success_delete_survey);
  } catch (error) {
    toastMessage("error", language[lng].error_delete_survey);
  }
};

export default fetchData;

export const fetchCombinedSurvey = async (lng = "english", params) => {
  try {
    const {
      page,
      limit,
      type,
      category,
      user,
      id,
      requestType,
      startCreatedAt,
      endCreatedAt,
    } = params;

    const userLoggedIn = await getStorage();
    const { token, role } = userLoggedIn;

    let requestBody = {
      ...params,
      category,
      user,
      id,
      type: requestType,
      startCreatedAt,
      endCreatedAt,
    };

    if (role === "user") {
      requestBody.user = [userLoggedIn.id];
    }

    let url = "/survey/combine";
    const options = {
      method: "GET",
      url: url,
      params: requestBody,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const queryRes = await apiInstance(options);

    let { data, count } = queryRes;

    return { data, count };
  } catch (error) {
    toastMessage("error", language[lng].unable_fetch_surveys);
  }
};
