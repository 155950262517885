import moment from "moment";
import { ON_ADD_FILTER, ON_RESET_FILTERS } from "../actions/Filters.js";

let filters = {};

if (!filters.startDate) {
  filters.startDate = moment()
    .startOf("D")
    .subtract(365, "days")
    .format("YYYY-MM-DD");
}

if (!filters.endDate) {
  filters.endDate = moment().endOf("day").add("d", 1).format("YYYY-MM-DD");
}

if (!filters.location) {
  filters.location = {
    value: "province",
    label: "Province",
  };
}

const initialState = {
  filters,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ON_ADD_FILTER:
      Object.keys(action.payload).forEach((key) => {
        state.filters[key] = action.payload[key];
      });

      return {
        ...state,
        filters: { ...state.filters },
      };
    case ON_RESET_FILTERS:
      if (action.fieldName) {
        delete state.filters[action.fieldName];
      } else {
        state.filters = {
          startDate: moment()
            .subtract(30, "days")
            .format("YYYY-MM-DD HH:mm:ss"),
          endDate: moment().format("YYYY-MM-DD HH:mm:ss"),
        };
      }
      return {
        ...state,
        filters: { ...state.filters },
      };
    default:
      return state;
  }
};
export default reducer;
