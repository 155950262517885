// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.badge {
  background-color: var(--text-color-10);
  color: var(--text-color);
  text-transform: capitalize;
}

.badge.badge-success,
.badge.badge-approved,
.badge.badge-Approved,
.badge.badge-Approval {
  background-color: var(--success-color-10);
  color: var(--success-color);
}

.badge.badge-rejected,
.badge.badge-Rejected,
.badge.badge-Rejection {
  background-color: var(--error-color-10);
  color: var(--error-color);
}

.badge-lg {
  font-size: var(--font-size);
}
`, "",{"version":3,"sources":["webpack://./src/app/common/styles/badge.styles.css"],"names":[],"mappings":"AAAA;EACE,sCAAsC;EACtC,wBAAwB;EACxB,0BAA0B;AAC5B;;AAEA;;;;EAIE,yCAAyC;EACzC,2BAA2B;AAC7B;;AAEA;;;EAGE,uCAAuC;EACvC,yBAAyB;AAC3B;;AAEA;EACE,2BAA2B;AAC7B","sourcesContent":[".badge {\r\n  background-color: var(--text-color-10);\r\n  color: var(--text-color);\r\n  text-transform: capitalize;\r\n}\r\n\r\n.badge.badge-success,\r\n.badge.badge-approved,\r\n.badge.badge-Approved,\r\n.badge.badge-Approval {\r\n  background-color: var(--success-color-10);\r\n  color: var(--success-color);\r\n}\r\n\r\n.badge.badge-rejected,\r\n.badge.badge-Rejected,\r\n.badge.badge-Rejection {\r\n  background-color: var(--error-color-10);\r\n  color: var(--error-color);\r\n}\r\n\r\n.badge-lg {\r\n  font-size: var(--font-size);\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
