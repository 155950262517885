import React, { useState } from "react";
import { Popup } from "react-leaflet";
import "./styles.css";
import { Button } from "../Button";
import { Modal } from "../Modal";
import ListOfFarmers from "../Geographical/List/ListOfFarmers";

const AppPopup = (props) => {
  const [showModal, setShowModal] = useState(false);
  return (
    <>
      <Popup>
        <div className="app-map-popup">
          {Object.keys(props.data || {}).map((key, i) => {
            return (
              <div key={i} className="item-container">
                <div>
                  <span style={{ textTransform: "capitalize" }}>{key}</span>
                </div>
                <div className="description">
                  <span className="text-bold">{props.data[key]}</span>
                </div>
              </div>
            );
          })}
          <Button text="View List" onPress={() => setShowModal(true)} />
        </div>
      </Popup>
      <Modal show={showModal} handleClose={() => setShowModal(false)} size="lg">
        <ListOfFarmers data={props.data} />
      </Modal>
    </>
  );
};

export default AppPopup;
