import React from "react";
import "./App.css";
import "./app/common/styles/scrollbar.styles.css";
import "./app/common/styles/auth.form.styles.css";
import "./app/common/styles/media.queries/general.media.queries.css";
import "./app/common/styles/border.styles.css";
import "./app/common/styles/card.styles.css";
import "./app/common/styles/dropdown.styles.css";
import "./app/common/styles/icons.styles.css";
import "./app/common/styles/label.styles.css";
import "./app/common/styles/margin.styles.css";
import "./app/common/styles/modals.styles.css";
import "./app/common/styles/text.styles.css";
import "./app/common/styles/ul.styles.css";
import "./app/common/styles/badge.styles.css";
import "./app/common/styles/hr.styles.css";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { AdminRoutes, CommonRoutes, UserRoutes } from "./app/routes";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en.json";
import ru from "javascript-time-ago/locale/ru.json";
import { connect } from "react-redux";

TimeAgo.addDefaultLocale(en);
TimeAgo.addLocale(ru);

class App extends React.Component {
  componentDidMount = async () => {
    // await handleNotification(this);
  };

  render() {
    return (
      <>
        <CommonRoutes />
        <AdminRoutes />
        <UserRoutes />
        <ToastContainer
          autoClose={5000}
          hideProgressBar={true}
          closeOnClick={true}
          pauseOnHover={true}
          draggable={true}
          progress={undefined}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { notifications } = state.Notifications;
  return {
    notifications,
  };
};
export default connect(mapStateToProps)(App);
