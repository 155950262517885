import React from "react";
import { Input, Select } from "../../../Input";
import { connect } from "react-redux";
import { Button } from "../../../Button";
import language from "../../../../language";
import icons from "../../../../constants/icons";
import { common_input_types } from "../../../../constants/document";

let childDefaultOption = {
  field: "",
  value: common_input_types()[0],
};

let defaultOption = {
  title: "",
  options: [childDefaultOption],
};

class MultipleAnswer extends React.Component {
  state = {
    choices: [],
    error: {},
  };

  componentDidMount() {
    if (this.props.multiAnswer.length === 0) {
      this.setState({ choices: [defaultOption] });
    }

    if (this.props.isUpdate) {
      this.formulateChoiceFromProps(this.props.multiAnswer);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.choices !== this.state.choices) {
      this.props.handleOptionsChanges(this.state.choices);
    }
  }

  formulateChoiceFromProps(data) {
    let { choices } = this.state;

    choices = data.map((el) => {
      let options = [];

      Object.keys(el).map((key) => {
        if (key !== "objectTitle") {
          options.push({
            field: key,
            value: {
              label: el[key],
              value: el[key],
            },
          });
        }
      });
      return {
        title: el.objectTitle,
        options,
      };
    });

    this.setState({
      choices,
    });
  }

  onChangeInput({ field, e, index }) {
    let { choices } = this.state;
    let value = e.target ? e.target.value : e;

    if (field === "title") {
      choices[index].title = value;
    }

    this.setState({ choices });

    this.props.handleOptionsChanges(choices);
  }

  onChangeChildInput({ field, e, parentIndex, childIndex }) {
    let { choices } = this.state;
    let { options = [childDefaultOption] } = choices[parentIndex];

    let value = e.target ? e.target.value : e;

    options[childIndex][field] = value;

    this.setState({ choices });

    this.props.handleOptionsChanges(choices);
  }

  handleAddChoice = () => {
    let { choices } = this.state;

    defaultOption = {
      title: "",
      options: [],
    };

    choices.push(defaultOption);

    this.setState({ choices });

    this.props.handleOptionsChanges(choices);
  };

  handleRemoveParentChoice(parentIndex) {
    let { choices } = this.state;

    choices.splice(parentIndex, 1);

    this.setState({ choices });

    this.props.handleOptionsChanges(choices);
  }

  handleRemoveChildChoice({ parentIndex, childIndex }) {
    let { choices } = this.state;

    choices[parentIndex].options.splice(childIndex, 1);

    this.setState({ choices });
  }

  handleAddChoiceMultiAnswer = (parentIndex) => {
    let { choices } = this.state;
    let { options } = choices[parentIndex];

    childDefaultOption = {
      field: "",
      value: common_input_types[0],
    };

    options.push(childDefaultOption);

    this.setState({ choices });

    this.props.handleOptionsChanges(choices);
  };

  renderObject(field, index) {
    return (
      <div className="card-body panel-default">
        <div className="row mb-3">
          <div className="col-md-12">
            <Input
              label={language[this.props.defaultLanguage].object_title}
              placeholder={
                language[this.props.defaultLanguage].object_title_placeholder
              }
              value={this.state?.choices[index]?.title}
              onChange={(e) => this.onChangeInput({ field: "title", e, index })}
            />
          </div>
          {this.state.choices[index]?.options?.map((fields, childIndex) => {
            return this.renderMultiAnswerFields({
              parentIndex: index,
              childIndex,
              fields,
            });
          })}
        </div>
        <div className="col-md-12 d-flex align-content-center gap-3">
          <Button
            icon={icons.add}
            onPress={() => this.handleAddChoiceMultiAnswer(index)}
            className="btn-xsm btn-default"
            text="Add"
          />
          {this.state.choices[index].options &&
            this.state.choices[index].options.length > 0 && (
              <Button
                icon={icons.remove}
                onPress={() => this.handleRemoveParentChoice(index)}
                className="btn-xsm btn-transparent primary"
                text="Remove"
              />
            )}
        </div>
      </div>
    );
  }

  renderMultiAnswerFields({ parentIndex, childIndex, fields }) {
    return (
      <div className="row" style={{ position: "relative" }}>
        <div className="col-md-6">
          <Input
            label={language[this.props.defaultLanguage].field_name}
            placeholder={
              language[this.props.defaultLanguage].field_name_placeholder
            }
            value={fields?.field}
            onChange={(e) =>
              this.onChangeChildInput({
                field: "field",
                e,
                parentIndex,
                childIndex,
              })
            }
          />
        </div>
        <div className="col-md-6">
          <Select
            options={common_input_types(this.props.defaultLanguage)}
            label={language[this.props.defaultLanguage].value_type}
            placeholder={language[this.props.defaultLanguage].select}
            value={fields?.value}
            onChange={(e) =>
              this.onChangeChildInput({
                field: "value",
                e,
                parentIndex,
                childIndex,
              })
            }
          />
        </div>
        <div
          className="col-md-12"
          style={{
            position: "relative",
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
          }}
        >
          <div
            className="icon-container"
            onClick={() =>
              this.handleRemoveChildChoice({ parentIndex, childIndex })
            }
            style={{ position: "absolute", right: -20, bottom: 18 }}
          >
            <span className={process.env.REACT_APP_ICONS_TYPE}>
              {icons.delete}
            </span>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div style={{ marginBottom: "2rem" }}>
        {this.state.choices.map((choice, i) => {
          return this.renderObject(choice, i);
        })}
        <Button
          text={language[this.props.defaultLanguage].add_object}
          onPress={this.handleAddChoice.bind(this)}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(MultipleAnswer);
