import React from "react";

import { connect } from "react-redux";
import language from "../../language";
import { Table } from "../Table";
import handleSearch from "../../utils/handleSearch";
import surveyQuery from "../../utils/queries/surveysQuery";
import handleFormateDataCSV from "../../utils/handleFormateDataCSV";
import dynamicTableData from "../../utils/dynamicTableData";
import { CSVLink } from "react-csv";
import moment from "moment";

let copyData = [];

class FarmerSurveyAnswer extends React.Component {
  state = {
    totalPageCount: 20,
    page: 1,
    limit: 10,
    tableHeaders: [],
    data: [],
    csvData: [],
  };

  componentDidMount() {
    this.getFarmerInfo(true);
  }

  componentDidUpdate(prevProps) {
    if (this.props.activeTab !== prevProps.activeTab) {
      this.getFarmerInfo(true);
    }
  }

  returnFilters() {
    let { filters } = this.props;
    const { page, limit } = this.state;
    return {
      page,
      limit,
      ...filters,
    };
  }

  getFarmerInfo = async (isLoading) => {
    try {
      let { defaultLanguage } = this.props;

      this.setState({ isLoading, data: [] });

      const { data, count } = await surveyQuery(
        defaultLanguage,
        this.returnFilters()
      );

      const { rowData, tableHeaders } = dynamicTableData(this, data);

      this.setState({
        data: rowData,
        tableHeaders,
        totalPageCount: count,
        isLoading: false,
      });
      console.log("farmer survey information*****", data);
    } catch (error) {
      this.setState({
        isLoading: false,
      });
    }
  };

  handlePagination(page) {
    this.setState(
      {
        page,
      },
      () => {
        this.getData(true);
      }
    );
  }

  handleShowModal(modal, modalTitle, selectedData = {}) {
    this.setState({
      [modal]: true,
      modalTitle: modalTitle,
      selectedData,
    });
  }

  handleCloseModal(modal) {
    this.setState({
      [modal]: false,
    });
  }

  downloadExcel = async () => {
    try {
      this.setState({
        isLoading: true,
      });

      const { defaultLanguage } = this.props;

      const { data, count } = await surveyQuery(
        defaultLanguage,
        this.returnFilters()
      );

      const { tableHeaders } = dynamicTableData(this, data);

      let formateData = [];
      let headers = tableHeaders.map((el) => el.title);

      data.forEach((el) => {
        formateData.push(handleFormateDataCSV(el.questionnaireAnswer, headers));
      });

      this.setState(
        {
          isLoading: false,
          csvData: [headers, ...formateData],
        },
        () => this.refs.csvDownload?.link.click()
      );
    } catch (error) {
      this.setState({
        isLoadingFSC: false,
      });
    }
  };

  render() {
    return (
      <>
        <Table
          data={this.state.data}
          isSearch
          page={this.state.page}
          limit={this.state.limit}
          search_text={this.state.search_text}
          handleSearch={(e) =>
            handleSearch(this, {
              copyData,
              value: e.target.value,
            })
          }
          totalPageCount={this.state.totalPageCount}
          isLoading={this.state.isLoading}
          handlePagination={this.handlePagination.bind(this)}
          headers={this.state.tableHeaders}
          filters={[
            {
              type: "export",
              title: language[this.props.defaultLanguage].export_,
              button_type: "dropdown",
              icon: "download",
              options: [
                {
                  name: "CSV",
                  onPress: this.downloadExcel.bind(this),
                },
              ],
            },
          ]}
          actions={[{}]}
        />
        <CSVLink
          ref="csvDownload"
          filename={this.props.activeTab + " - " + moment().format("lll")}
          data={this.state.csvData}
        ></CSVLink>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(FarmerSurveyAnswer);
