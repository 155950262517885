import React from "react";
import { connect } from "react-redux";
import { Input } from "../Input";
import icons from "../../constants/icons";
import language from "../../language";
import "./styles.css";
import { Button } from "../Button";
import { onResetFilters } from "../../actions/Filters";
import DepartmentFilter from "./DepartmentFilter";
import ServiceFilter from "./ServiceFilter";
import DateFilter from "./DateFilter";
import LocationFilter from "./LocationFilter";

class MainFilters extends React.Component {
  render() {
    return (
      <div className="filters-container ">
        {this.props?.filters?.includes("search") && (
          <Input
            leftIcon={icons.search}
            styles={{ margin: 0, flex: 1 }}
            placeholder={
              language[this.props.defaultLanguage].main_search_placeholder
            }
            className="sm"
          />
        )}
        {this.props?.filters?.includes("location") && <LocationFilter />}
        {this.props?.filters?.includes("service") && <ServiceFilter />}
        {this.props?.filters?.includes("date_range") && <DateFilter />}
        <Button
          text="Clear Filter"
          className="btn-default btn-sm"
          onPress={() => this.props.dispatch(onResetFilters())}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(MainFilters);
