import React from "react";
import { connect } from "react-redux";
import { getStorage } from "../../../../utils/storage";
import { Farmers } from "../../../../components/Farmers";

class ListFarmersScreen extends React.Component {
  state = {
    user: {},
  };

  componentDidMount = async () => {
    const user = await getStorage();

    this.setState({ user });
  };

  render() {
    return (
      <>
        <div className="card card-dashboard-content">
          <div className="card-body">
            <Farmers {...this.props} />
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(ListFarmersScreen);
