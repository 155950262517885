import { CardHeader } from "../Header";
import { Switch } from "../Input";

const Permissions = (props) => {
  return (
    <div className={props.className} key={props.key}>
      <div style={{ marginBottom: ".5rem" }}>
        <CardHeader title={props.title} borderBottom />
        <div className="card-body">
          {props?.operations?.map((opItem, menuIndex) => {
            return (
              <div className={props.title} key={menuIndex}>
                <Switch
                  label={opItem.label || opItem}
                  checked={
                    props.operationMenus[opItem.value || opItem] &&
                    props.operationMenus[opItem.value || opItem]?.includes(
                      props.menuItem
                    )
                  }
                  onCheck={() =>
                    props.handleCheckOperations
                      ? props.handleCheckOperations(
                          props.menuItem,
                          opItem.value
                        )
                      : null
                  }
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Permissions;
