import React from "react";
import { connect } from "react-redux";
import { getStorage } from "../../utils/storage";
import { Permissions } from "../AccessRole";
import icons from "../../constants/icons";
import { Input, Select } from "../Input";
import language from "../../language";
import { menus } from "../../constants/accessRolesList";

class RolesPermissions extends React.Component {
  state = {
    accessRole: {},
  };

  componentDidMount = async () => {
    const { accessRole = {} } = await getStorage();

    this.setState({ accessRole });
  };
  render() {
    let selectedMenus = [];
    for (let menu of menus(this.props.defaultLanguage)) {
      if (this.state.accessRole?.permissions?.menus?.includes(menu.value)) {
        selectedMenus.push(menu);
      }
    }

    return (
      <>
        <>
          <div className="col-md-6">
            <Input
              leftIcon={icons.role}
              placeholder={
                language[this.props.defaultLanguage]
                  .access_role_name_placeholder
              }
              label={language[this.props.defaultLanguage].access_role}
              required
              value={this.state.accessRole.name}
              disabled
            />
            <Select
              leftIcon={icons.menu}
              options={this.state.accessRole?.permissions?.menus?.map((m) => {
                return {
                  value: m,
                  label: m,
                };
              })}
              placeholder={language[this.props.defaultLanguage].select}
              label={language[this.props.defaultLanguage].menus}
              required
              value={this.state.accessRole?.permissions?.menus?.map((m) => {
                return {
                  value: m,
                  label: m,
                };
              })}
              disabled
              isMulti
            />
          </div>
          {selectedMenus?.map((menu, m) => {
            return (
              <div className="col-md-12" key={m}>
                <Permissions
                  title={menu.value}
                  key={m}
                  operationMenus={
                    this.state.accessRole?.permissions?.operationMenus
                  }
                  operations={menu.operations}
                  menuItem={menu.value}
                />
              </div>
            );
          })}
        </>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(RolesPermissions);
