import HomeScreen from "../screens/AdminDashboard/HomeScreen";
import ServiceScreen from "../screens/AdminDashboard/Services/ServiceScreen";
import AccountScreen from "../screens/AdminDashboard/AccountScreen";
import SettingScreen from "../screens/AdminDashboard/SettingScreen";
import language from "../language";
import icons from "./icons";
import GeographicalNavigationScreen from "../screens/AdminDashboard/Geographical/GeographicalNavigationScreen";
import SurveysScreen from "../screens/common/SurveysScreens";
import LocationsScreen from "../screens/AdminDashboard/LocationsScreen";
import ListFarmersScreen from "../screens/AdminDashboard/Home/Farmers/ListFarmersScreen";
import serviceQuery from "../utils/queries/serviceQuery";
import capitalizeText from "../utils/capitalizeText";
import { onAddSidebarMenus, onUpdateSidebarMenus } from "../actions/Sidebar";

const getMenus = async (lng) => {
  try {
    const data = await serviceQuery(lng);

    const subMenus = [];
    let groups = {}; // Initialize groups outside the loop

    data.forEach((item) => {
      if (item?.settings?.listOnSidebar) {
        if (item.settings.group) {
          const groupName = item.settings.group.toUpperCase() || "";
          if (!groups[groupName]) {
            groups[groupName] = [];
          }

          // Add item to the appropriate group
          const menuItem = {
            path: `/${item._id}`,
            key: item._id,
            name: capitalizeText(item.settings.menuName || item.name),
            component: (
              <ListFarmersScreen
                cardCounts={item?.settings?.cardCounts}
                type={item.type}
                id={item._id}
                name={item.settings.menuName || item.name}
              />
            ),
          };
          const insertPosition =
            item.settings.position || groups[groupName].length;

          // Insert the menu item at the specific position
          groups[groupName].splice(insertPosition, 0, menuItem);

          // Ensure the 'PARTICIPANT INFO' group is moved to the first position
          Object.keys(groups).forEach((key) => {
            if (key?.toUpperCase() === "PARTICIPANT INFO") {
              // Get the 'Participant Info' group
              const participantInfoGroup = groups[key];

              // Remove it from its original position
              delete groups[key];

              // Reinsert 'Participant Info' group at the first position
              groups = {
                [key]: participantInfoGroup, // Insert at the beginning
                ...groups, // Keep the rest of the groups
              };
            }
          });

          console.log("groups", groups);
        } else {
          subMenus.push({
            path: `/${item._id}`,
            key: item._id,
            name: capitalizeText(item.name),
            component: (
              <ListFarmersScreen
                {...item}
                type={item.type}
                id={item._id}
                name={item.name}
                card={item.name}
              />
            ),
          });
        }
      }
    });

    let menus = [];

    for (let groupName in groups) {
      menus.push({
        parentPath: "/dashboard",
        path: `/${groupName}`,
        name: capitalizeText(groupName),
        icon: icons.dashboard,
        menus: groups[groupName].map((menu) => {
          return menu;
        }),
      });
    }

    return menus;
  } catch (error) {
    console.log(error);
  }
};

const data = async (that) => {
  try {
    const lng = that.props.defaultLanguage || "english";

    let sidebarList = [
      {
        parentPath: "/dashboard",
        path: "/home",
        name: language[lng].home,
        icon: icons.home,
        component: <HomeScreen />,
        accessName: "Home",
      },
      {
        type: "DYNAMIC-MENUS",
        isLoading: true,
      },
      {
        type: "FINANCE",
        isLoading: true,
      },
      {
        type: "KPI",
        isLoading: true,
      },
      {
        type: "IMPACT",
        isLoading: true,
      },
      {
        parentPath: "/dashboard",
        path: "/geographical navigation",
        name: language[lng].geographical_navigation,
        icon: icons.map,
        accessName: "Geographical Navigation",
        component: <GeographicalNavigationScreen />,
      },
      {
        parentPath: "/dashboard",
        path: "/surveys",
        name: language[lng].surveys,
        accessName: "Surveys",
        icon: icons.projects,
        component: <SurveysScreen role="dashboard" />,
      },
      {
        parentPath: "/dashboard",
        path: "/services",
        accessName: "Services",
        name: language[lng].services,
        icon: icons.service,
        component: <ServiceScreen />,
      },
      {
        parentPath: "/dashboard",
        path: "/locations",
        name: language[lng].locations,
        icon: icons.location,
        accessName: "Locations",
        component: <LocationsScreen />,
      },
      // {
      //   parentPath: "/dashboard",
      //   path: "/communication",
      //   name: language[lng].communication,
      //   icon: icons.email,
      //   component: <CommunicationsScreen />,
      // },
      {
        parentPath: "/dashboard",
        path: "/accounts",
        name: language[lng].accounts,
        accessName: "Accounts",
        icon: icons.users,
        component: <AccountScreen />,
      },
    ];

    let formateMenus = await Promise.all(
      sidebarList.map(async (el, index) => {
        const userLoggedIn = JSON.parse(
          sessionStorage.getItem(process.env.REACT_APP_NAME) || {}
        );

        const { accessRole = {}, accountType, role } = userLoggedIn;
        const { permissions = {} } = accessRole;
        const { menus = [] } = permissions;

        if (accountType === "superAdmin" || role === "superAdmin") {
          handleUpdateMenus(that, { el, lng, index });
          return el;
        }

        if (menus.includes(el.accessName)) {
          handleUpdateMenus(that, { el, lng, index });

          let itemMenus = [];

          if (el.menus) {
            itemMenus = el?.menus?.map((itemM) => {
              if (
                menus.includes(itemM.accessName) ||
                menus.includes(itemM.value)
              ) {
                return itemM;
              }
            });
          }

          if (itemMenus.length > 0) {
            el.menus = itemMenus.filter((fElItem) => fElItem);
          }

          return el;
        }
      })
    );

    const menus = [
      ...formateMenus.filter((el) => el),
      {
        parentPath: "/dashboard",
        path: "/settings",
        name: language[lng].settings,
        icon: icons.setting,
        component: <SettingScreen />,
      },
    ];

    that.props.dispatch(onAddSidebarMenus(menus));
  } catch (error) {
    console.log(error);
    window.location.href = "/";
  }
};

const handleUpdateMenus = async (that, { el, lng, index }) => {
  if (el.type === "DYNAMIC-MENUS") {
    const menu = await getMenus(lng);

    if (Array.isArray(menu)) {
      for (let [elIndex, el] of menu.entries()) {
        that.props.dispatch(onUpdateSidebarMenus(el, elIndex + index));
      }
    } else {
    }
  }

  return el;
};

export default data;
