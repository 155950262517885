import React from "react";
import { connect } from "react-redux";
import language from "../../language";
import { Table } from "../Table";
import { CSVLink } from "react-csv";
import notificationQuery, {
  updateNotification,
} from "../../utils/queries/notificationQuery";
import exportPDF from "../../utils/exportPDF";
import handleSearch from "../../utils/handleSearch";
import icons from "../../constants/icons";
import { getStorage } from "../../utils/storage";
import socket from "../../utils/socket/connection";

let copyData = [];

class Notifications extends React.Component {
  state = {
    isLoading: false,
    data: [],
    page: 1,
    limit: 10,
    search_text: "",
    selected_data: {},
    delete_value: "",
    error: {},
    csvData: [],
    user: {},
  };

  componentDidMount = async () => {
    const user = await getStorage();
    this.setState({ user });
    await this.getData(true);

    socket.on("notification", async () => {
      this.getData(true);
    });
  };

  getData = async (isLoading) => {
    try {
      const { page, limit } = this.state;
      const { defaultLanguage, viewed } = this.props;
      const { role, id } = await getStorage();

      this.setState({ isLoading });

      let paramsData = {
        lng: defaultLanguage,
        page,
        limit,
      };

      if (viewed) {
        paramsData.viewedBy = [id];
      }

      if (role === "superAdmin") {
        paramsData.viewed = viewed;
        delete paramsData.viewedBy;
      }

      const { data, count } = await notificationQuery(this, paramsData);

      this.setState({
        data: data,
        totalPageCount: count,
        isLoading: false,
      });

      copyData = data.slice(0);
    } catch (error) {
      this.setState({
        isLoading: false,
      });
    }
  };

  handlePagination(page) {
    this.setState(
      {
        page,
      },
      () => {
        this.getDepartments(true);
      }
    );
  }

  handleShowModal(modal, modalTitle, selected_data = {}) {
    this.setState({
      [modal]: true,
      modalTitle: modalTitle,
      selected_data,
    });
  }

  handleCloseModal(modal) {
    this.setState({
      [modal]: false,
    });
  }

  onNavigate(notificationBody) {
    const { defaultLanguage } = this.props;
    const { role, id } = this.state.user;

    const urlPath =
      role === "user"
        ? "/user/surveys/single-view/"
        : "/dashboard/surveys/single-view/";

    let { viewedBy = [] } = notificationBody;

    if (!viewedBy?.includes(id)) {
      viewedBy.push(id);
    }

    //update notification to viewed
    updateNotification({
      lng: defaultLanguage,
      id: notificationBody._id,
      // viewed: true,
      viewedBy,
    });

    window.location.href = urlPath + "" + notificationBody?.body?._id;
  }

  returnTableHeaders = () => {
    let headers = [
      {
        title: language[this.props.defaultLanguage].notification,
        key: "body.description",
        type: "link",
        onPress: (item) => this.onNavigate(item),
      },
      {
        title: language[this.props.defaultLanguage].date_time,
        key: "createdAt",
        type: "timeAgo",
      },
      {
        title: "",
        key: "multiple-actions",
        actions: [
          {
            icon: icons.eye_on,
            title: "View notification",
            onPress: (item) => this.onNavigate(item),
          },
        ],
      },
    ];

    return headers;
  };

  render() {
    const { export_ } = language[this.props.defaultLanguage];

    return (
      <>
        <Table
          data={this.state.data}
          isSearch
          page={this.state.page}
          limit={this.state.limit}
          search_text={this.state.search_text}
          handleSearch={(e) =>
            handleSearch(this, {
              copyData,
              value: e.target.value,
            })
          }
          totalPageCount={this.state.totalPageCount}
          isLoading={this.state.isLoading}
          handlePagination={this.handlePagination.bind(this)}
          headers={this.returnTableHeaders()}
          rowPress={(item) =>
            this.handleShowModal("showModal", item.name, item)
          }
        />

        <CSVLink
          ref="csvDownload"
          filename={"Notifications_" + new Date().getTime()}
          data={this.state.csvData}
        ></CSVLink>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(Notifications);
