import language from "../language";
export default (lng = "english") => [
  {
    label: language[lng].district,
    value: "district",
  },
  {
    label: language[lng].province,
    value: "province",
  },
  {
    label: language[lng].sector,
    value: "sector",
  },
  {
    label: language[lng].cell,
    value: "cell",
  },
  {
    label: language[lng].village,
    value: "village",
  },
];
