import React from "react";
import { connect } from "react-redux";
import { Input, Select } from "../../Input";
import { Button } from "../../Button";
import language from "../../../language";
import toastMessage from "../../../utils/toastMessage";
import languages from "../../../constants/languages";
import { apiInstance } from "../../../utils/axiosInstance";

class NewTranslation extends React.Component {
  state = {
    error: {},
    languages: Object.keys(language),
    serviceLanguages: [this.props.defaultLanguage],
    maxDays: "",
  };

  onChangeText(field, e) {
    let { error } = this.state;
    delete error[field];

    this.setState({
      [field]: e.target ? e.target.value : e,
      error,
    });
  }

  handleAddTranslation() {
    const { defaultLanguage } = this.props;
    const { languages, serviceLanguages } = this.state;

    for (let el of languages) {
      if (el !== defaultLanguage && !serviceLanguages.includes(el)) {
        serviceLanguages.push(el);
      }
    }

    this.setState({ serviceLanguages });
  }

  handleRemoveTranslation(index) {
    let { serviceLanguages } = this.state;

    serviceLanguages.splice(index, 1);

    this.setState({ serviceLanguages });
  }

  renderServiceName = (lng = this.props.defaultLanguage, index) => {
    const { serviceLanguages, error } = this.state;

    return (
      <Input
        key={index}
        placeholder={language[lng].service_name_placeholder}
        label={language[lng].service_name + "(" + lng + ")"}
        required
        value={this.state["serviceName_" + lng]}
        onChange={(e) => this.onChangeText("serviceName_" + lng, e)}
        error={error["serviceName_" + lng]}
        helperLink={
          serviceLanguages.length === 1
            ? language[lng].add_translation
            : language[lng].remove_translation
        }
        onPressHelperLink={
          serviceLanguages.length === 1
            ? this.handleAddTranslation.bind(this)
            : this.handleRemoveTranslation.bind(this, index)
        }
      />
    );
  };

  validateForm() {
    let { category, maxDays, error, serviceLanguages } = this.state;

    if (!category) {
      error.category = language[this.props.defaultLanguage].category_required;
    }

    if (maxDays === "") {
      error.maxDays = language[this.props.defaultLanguage].maxDays_required;
    }

    for (let lng of serviceLanguages) {
      if (!this.state["serviceName_" + lng]) {
        error["serviceName_" + lng] = language[lng].service_name_required;
      } else if (this.state["serviceName_" + lng] === "") {
        error["serviceName_" + lng] = language[lng].service_name_required;
      }
    }

    this.setState({ error });
  }

  onSubmit = async () => {
    await this.validateForm();

    if (Object.keys(this.state.error).length === 0) {
      this.setState({
        isSubmitting: true,
      });

      const { category, maxDays } = this.state;
      const serviceId = 10221;

      window.location.href = `/dashboard/services/single-view/${serviceId}`;

      return;

      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: {},
      };

      apiInstance(options).then((data) => {
        this.setState({
          isSubmitting: false,
        });

        toastMessage(
          "success",
          language[this.props.defaultLanguage].add_institution_success
        );
      });
    }
  };

  render() {
    return (
      <div>
        <div className="card-body">
          <Select
            options={languages}
            placeholder={language[this.props.defaultLanguage].select}
            label={language[this.props.defaultLanguage].language}
            required
            value={this.state.language}
            onChange={(e) => this.onChangeText("language", e)}
            error={this.state.error.language}
          />
          <Input
            label={language[this.props.defaultLanguage].default_translation}
            required
            value={this.state.defaultTranslation}
            onChange={(e) => this.onChangeText("defaultTranslation", e)}
            error={this.state.error.defaultTranslation}
            disabled
          />
          {this.state?.language?.value && (
            <Input
              label={language[this.props.defaultLanguage].translation}
              required
              value={this.state["translation_" + this.state?.language?.value]}
              onChange={(e) =>
                this.onChangeText(
                  "translation_" + this.state?.language?.value,
                  e
                )
              }
              error={
                this.state.error["translation_" + this.state?.language?.value]
              }
            />
          )}
        </div>
        <div className="modal-footer gap-2">
          <Button
            text={language[this.props.defaultLanguage].cancel}
            onPress={this.props.handleCloseModal}
            className="btn-default"
          />
          <Button
            text={language[this.props.defaultLanguage].submit}
            onPress={this.onSubmit.bind(this)}
            isSubmitting={this.state.isSubmitting}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(NewTranslation);
