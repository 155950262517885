import "./styles.css";

const Loading = (props) => {
  return (
    <div className={props.className}>
      <span className={`loader`}></span>
      {/* ${props.className} */}
    </div>
  );
};

export default Loading;
