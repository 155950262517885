import { Navbar } from "../components/Navbar";
import "./layout.css";

const CenterLayout = (props) => {
  return (
    <div className="center-layout">
      <div className="container">
        <div className="col-md-10 mx-auto">{props.children}</div>
      </div>
    </div>
  );
};

export default CenterLayout;
