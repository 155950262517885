import React from "react";
import { withRouter } from "../../../utils/withRouter";
import language from "../../../language";
import { DashboardHeader } from "../../../components/Header";
import { SingleFarmer } from "../../../components/Farmers";
import { Breadcrumb } from "../../../components/Breadcrumb";
import { connect } from "react-redux";

class ViewSingleRequestScreen extends React.Component {
  render() {
    return (
      <>
        <Breadcrumb
          homeUrl={`/dashboard/home`}
          data={[
            {
              name: language[this.props.defaultLanguage].surveys,
              url: `/dashboard/services`,
            },
            {
              name: this.props.routeParams.uniqueNumber,
              active: true,
            },
          ]}
        />
        <DashboardHeader title={this.props.routeParams.uniqueNumber} />
        <SingleFarmer {...this.props?.routeParams} />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;

  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(withRouter(ViewSingleRequestScreen));
