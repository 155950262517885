import React from "react";
import { connect } from "react-redux";
import surveyQuery from "../../../utils/queries/surveysQuery";
import dynamicTableData from "../../../utils/dynamicTableData";
import { Table } from "../../Table";
import formateFilters from "../../../utils/formateFilters";
import toastMessage from "../../../utils/toastMessage";
import capitalizeText from "../../../utils/capitalizeText";
import handleSearch from "../../../utils/handleSearch";

let copyData = [];

class ListOfFarmers extends React.Component {
  state = {
    isLoading: false,
    farmers: [],
    page: 1,
    limit: 10,
  };

  componentDidMount() {
    this.getFarmers(true);
  }

  returnFilters() {
    const { page, limit } = this.state;

    const { filters } = this.props;

    let _filters = {
      page,
      limit,
      // ["questionnaireAnswer.questionText"]: ["District", "district"],
      ["questionnaireAnswer.answer"]: [this.props.data.district],
      ...formateFilters(filters),
    };

    delete _filters.startCreatedAt;
    delete _filters.endCreatedAt;

    return _filters;
  }

  getFarmers = async (isLoading) => {
    try {
      const { defaultLanguage } = this.props;

      this.setState({ isLoading, data: [], tableHeaders: [] });

      const { data, count } = await surveyQuery(defaultLanguage, {
        ...this.returnFilters(),
      });

      const { tableHeaders, rowData } = dynamicTableData(this, data);

      this.setState({
        data: rowData,
        tableHeaders,
        totalPageCount: count,
        isLoading: false,
      });
      copyData = rowData.slice(0);
    } catch (error) {
      console.log("tableHeaders****", error);
      this.setState({
        isLoading: false,
      });
    }
  };

  handlePagination(page) {
    this.setState(
      {
        page,
      },
      () => {
        this.getFarmers(true);
      }
    );
  }

  handlePressSearch = async () => {
    try {
      const { search_text } = this.state;
      const { defaultLanguage } = this.props;

      if (search_text === "")
        return toastMessage("error", "Search text is required");

      this.setState({ isLoading: true });

      const { data, count } = await surveyQuery(defaultLanguage, {
        ["questionnaireAnswer.answer"]: [
          search_text,
          search_text.toUpperCase(),
          capitalizeText(search_text),
        ],
        ...this.returnFilters(),
        page: 1,
      });

      const { tableHeaders, rowData } = dynamicTableData(this, data);

      this.setState({
        data: rowData,
        tableHeaders,
        totalPageCount: count,
        isLoading: false,
      });
    } catch (error) {
      toastMessage("error", error);

      this.setState({
        isLoading: false,
      });
    }
  };
  render() {
    console.log(this.props);
    return (
      <div className="card">
        <div className="card-body">
          <Table
            isSearch
            handleSearch={(e) =>
              handleSearch(this, {
                copyData,
                value: e.target.value,
              })
            }
            search_text={this.state.search_text}
            addSearchButton
            handlePressSearch={this.handlePressSearch.bind(this)}
            isLoading={this.state.isLoading}
            data={this.state.data}
            headers={this.state.tableHeaders}
            totalPageCount={this.state.totalPageCount}
            handlePagination={this.handlePagination.bind(this)}
            page={this.state.page}
            limit={this.state.limit}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  const { filters } = state.Filters;

  return {
    defaultLanguage,
    filters,
  };
};

export default connect(mapStateToProps)(ListOfFarmers);
