import React from "react";
import { connect } from "react-redux";
import language from "../../language";
import { Table } from "../Table";
import { CSVLink } from "react-csv";
import exportPDF from "../../utils/exportPDF";
import handleSearch from "../../utils/handleSearch";
import surveysQuery from "../../utils/queries/surveysQuery";
import { getStorage } from "../../utils/storage";
import icons from "../../constants/icons";
import { Modal } from "../Modal";
import { Services } from "../UserServices";

let copyData = [];
class Requests extends React.Component {
    state = {
        isLoading: false,
        data: [],
        page: 1,
        limit: 10,
        search_text: "",
        error: {},
        csvData: [],
        selected_data: {},
        user: {},
    };

    componentDidMount = async () => {
        const user = await getStorage();

        this.setState({ user });

        await this.getData(true);
    };

    returnDataFilters = async () => {
        const { page, limit, user } = this.state;

        const { filters = {} } = this.props;
        const { services, startDate, endDate } = filters;
        let { type, status, startCreatedAt, endCreatedAt } = this.props;

        if (!startCreatedAt) {
            startCreatedAt = new Date(startDate).getTime();
        }

        if (!endCreatedAt) {
            endCreatedAt = new Date(endDate).getTime();
        }

        return {
            page,
            limit,
            status,
            requestType: type,
            startCreatedAt,
            endCreatedAt,
            services,
            user: user._id,
        };
    };

    componentDidUpdate(prevProps) {
        if (prevProps.filters !== this.props.filters) {
            this.getData(true);
        }
    }

    getData = async (isLoading) => {
        try {
            const { defaultLanguage } = this.props;

            this.setState({ isLoading });

            const { data, count } = await surveysQuery(
                defaultLanguage,
                await this.returnDataFilters(),
            );
            const filteredData = data.filter(item => item.service?.name === "Individual Farmer Registraction");
            this.setState({
                data: filteredData,
                isLoading: false,
                totalPageCount: count,
            });

            copyData = data.slice(0);
        } catch (error) {
            this.setState({
                isLoading: false,
            });
        }
    };
    handlePagination(page) {
        this.setState(
            {
                page,
            },
            () => {
                this.getData(true);
            }
        );
    }

    handleShowModal(modal, modalTitle, selected_data = {}) {
        this.setState({
            [modal]: true,
            modalTitle: modalTitle,
            selected_data,
        });
    }

    handleCloseModal(modal) {
        this.setState({
            [modal]: false,
        });
    }

    returnTableHeaders() {

        let headers = [
            {
                title: language[this.props.defaultLanguage].firstName,
                key: "questionnaireAnswer[1].answer",
                type: "link",
                onPress: (item) => (window.location.href = "/user/surveys/" + item._id),
            },

            {
                title: language[this.props.defaultLanguage].lastName,
                key: "questionnaireAnswer[2].answer",
            },

            {
                title: language[this.props.defaultLanguage].Gender,
                key: "questionnaireAnswer[0].answer",
            },
            {
                title: language[this.props.defaultLanguage].phone_number,
                key: "questionnaireAnswer[3].answer",
            },
            // {
            //     title: language[this.props.defaultLanguage].job_type,
            //     key: "questionnaireAnswer[20].answer",
            // },
            {
                title: language[this.props.defaultLanguage].services,
                key: "service.name",
            },
            // {
            //     title: language[this.props.defaultLanguage].date,
            //     key: "questionnaireAnswer[12].answer",
            // },
            {
                title: language[this.props.defaultLanguage].Identity,
                key: "questionnaireAnswer[4].answer",
            },

            {
                title: language[this.props.defaultLanguage].districts,
                key: "questionnaireAnswer[12].answer",
            },
            {
                title: language[this.props.defaultLanguage].status,
                key: "questionnaireAnswer[15].answer",
            },
            {
                title: language[this.props.defaultLanguage].created_at,
                key: "createdAt",
                type: "timeAgo",
            },
            {
                title: "",
                key: "multiple-actions",
                actions: [
                    {
                        icon: icons.eye_on,
                        title: language[this.props.defaultLanguage].view_application,
                        onPress: (item) =>
                            (window.location.href = "/user/surveys/" + item._id),
                    },
                ],
            },
        ];

        return headers;
    }

    downloadExcel = async () => {
        try {
            this.setState({
                isLoading: true,
            });

            const { defaultLanguage } = this.props;

            const { data } = await surveysQuery(
                defaultLanguage,
                await this.returnDataFilters()
            );

            this.setState({
                isLoading: false,
                csvData: data,
            });

            this.refs.csvDownload?.link.click();
        } catch (error) {
            this.setState({
                isLoading: false,
            });
        }
    };

    downloadPDF = async () => {
        this.setState({
            isLoading: true,
        });

        const { defaultLanguage } = this.props;

        const headers = this.returnTableHeaders();

        const { data } = await surveysQuery(
            defaultLanguage,
            await this.returnDataFilters()
        );

        this.setState({
            isLoading: false,
        });

        exportPDF("Requests", headers, data);
    };

    returnFilters() {
        const { availableFilters = [], exportEnabled } = this.props;

        let listFilters = [];

        if (availableFilters.includes("services")) {
            listFilters.push({
                title: language[this.props.defaultLanguage].filter_by_service,
                button_type: "dropdown",
                icon: icons.service,
                filterName: "service",
                filterType: this.props.type,
            });
        }

        if (availableFilters.includes("date_range")) {
            listFilters.push({
                title: language[this.props.defaultLanguage].date_range,
                button_type: "dropdown",
                icon: icons.service,
                filterName: "date_range",
            });
        }

        if (exportEnabled) {
            listFilters.push({
                type: "export",
                title: language[this.props.defaultLanguage].export_,
                button_type: "dropdown",
                icon: "download",
                options: [
                    {
                        name: "PDF",
                        onPress: this.downloadPDF.bind(this),
                    },
                    {
                        name: "CSV",
                        onPress: this.downloadExcel.bind(this),
                    },
                ],
            });
        }

        return listFilters;
    }

    render() {
        return (
            <>
                <Table
                    data={this.state.data}
                    isSearch
                    page={this.state.page}
                    limit={this.state.limit}
                    search_text={this.state.search_text}
                    handleSearch={(e) =>
                        handleSearch(this, {
                            copyData,
                            value: e.target.value,
                        })
                    }
                    showAdd={this.props.showAdd}
                    addButtonText="New Entry"
                    handleAddPressed={() =>
                        this.handleShowModal(
                            "showServicesModal",
                            language[this.props.defaultLanguage].choose_services
                        )
                    }
                    totalPageCount={this.state.totalPageCount}
                    isLoading={this.state.isLoading}
                    handlePagination={this.handlePagination.bind(this)}
                    headers={this.returnTableHeaders()}
                    rowPress={(item) =>
                        this.handleShowModal("showModal", item.name, item)
                    }
                    filters={this.returnFilters()}
                />

                <Modal
                    handleClose={this.handleCloseModal.bind(this, "showServicesModal")}
                    show={this.state.showServicesModal}
                    showHeaderBottomBorder={false}
                    size="lg"
                    title={this.state.modalTitle}
                >
                    <Services />
                </Modal>

                <CSVLink
                    ref="csvDownload"
                    filename={"My Requests" + new Date()}
                    data={this.state.csvData}
                ></CSVLink>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    const { defaultLanguage } = state.Language;
    const { filters } = state.Filters;
    const { notifications } = state.Notifications;
    return {
        defaultLanguage,
        filters,
        notifications,
    };
};

export default connect(mapStateToProps)(Requests);
