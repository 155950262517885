import React from "react";
import moment from "moment";
import deepFind, {
  JSONStringRowView,
  returnMultiAnswer,
} from "../../utils/deepFind";
import { Button } from "../Button";
import { Link } from "react-router-dom";
import { TimeAgo } from "../TimeAgo";
import capitalizeText from "../../utils/capitalizeText";
import icons from "../../constants/icons";
import { getStorage } from "../../utils/storage";
import { connect } from "react-redux";
import language from "../../language";
import isJsonString from "../../utils/isJsonString.js";

class Row extends React.PureComponent {
  state = {
    user: {},
  };

  componentDidMount = async () => {
    const user = await getStorage();

    this.setState({ user: user });
  };
  render() {
    const { headers, rowPress, actions, item, keyRowIndex, notifications } =
      this.props;
    return (
      <>
        {headers &&
          headers.map((header, h) => {
            let hasActionBtn =
              header.key === "action" || header.key === "multiple-actions";

            if (
              Object.keys(header).includes("checkValue") &&
              Object.keys(header).includes("checkKey") &&
              header.checkValue !== deepFind(item, header.checkKey)
            ) {
              hasActionBtn = false;
            }

            let find_text = deepFind(item, header.key)
              ? deepFind(item, header.key)
              : "-";

            let text =
              header.isMoment && item[header.key] && item[header.key] !== ""
                ? moment(item[header.key]).format(header.formatTime)
                : `${find_text} `;

            return (
              <>
                {hasActionBtn ? (
                  <td key={h}>
                    {header.key === "multiple-actions" ? (
                      <div className="table-raw-multiple-actions">
                        {header.actions.map((btn, i) => {
                          const notificationCount = notifications.filter(
                            (notification) => {
                              const {
                                bodyID,
                                notificationID,
                                viewedBy = [],
                              } = notification || {};

                              if (
                                (!viewedBy?.includes(btn.user) &&
                                  item._id === bodyID) ||
                                item._id === notificationID
                              ) {
                                return item;
                              }
                            }
                          ).length;

                          return (
                            <div
                              key={i}
                              className="table-raw-multiple-actions-button"
                              title={btn.title}
                            >
                              <Button
                                className={`btn-sm btn-transparent ${btn.className}`}
                                style={{ color: "red" }}
                                icon={btn.icon}
                                onPress={() => btn.onPress(item, keyRowIndex)}
                                notificationCount={
                                  btn.showNotificationCount &&
                                  notificationCount > 0
                                    ? notificationCount
                                    : 0
                                }
                                notificationStyles={{
                                  position: "absolute",
                                  top: -10,
                                  right: -10,
                                }}
                              />
                            </div>
                          );
                        })}
                      </div>
                    ) : (
                      <div className="dropdown">
                        <Button
                          className="btn-transparent btn-sm "
                          type="dropdown"
                          id="dropdownMenuButton1"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          icon="more_vert"
                        />
                        <ul
                          className="dropdown-menu"
                          aria-labelledby="dropdownMenuButton1"
                        >
                          {actions &&
                            actions.map((action, a) => {
                              if (item.approved) return;

                              if (
                                (action.key === "approve" ||
                                  action.key === "reject") &&
                                item?.account?._id !== this.state.user.id
                              )
                                return;

                              if (
                                action.key === "approve" &&
                                !item.approved &&
                                item?.account?._id !== this.state.user.id
                              )
                                return;
                              if (
                                action.key === "reject" &&
                                !item.approved &&
                                item?.account?._id !== this.state.user.id
                              )
                                return;

                              if (
                                action.key === "sign_approve" &&
                                item.account._id !== this.state.user.id &&
                                item.level !== action.highestLevel
                              )
                                return;

                              if (
                                action.key === "sign_approve" &&
                                item.account._id === this.state.user.id &&
                                item.level !== action.highestLevel
                              )
                                return;

                              return (
                                <Link
                                  key={a}
                                  className={`dropdown-item ${
                                    !action.canAccess && "text-disabled-3"
                                  }`}
                                  to={action.route ? action.route : "#"}
                                  onClick={() =>
                                    action.canAccess
                                      ? action.onPress(item, keyRowIndex)
                                      : null
                                  }
                                >
                                  {action.name}
                                </Link>
                              );
                            })}
                        </ul>
                      </div>
                    )}
                  </td>
                ) : (
                  <td key={h}>
                    {header.type === "photo" ? (
                      <div>
                        <img
                          onClick={() =>
                            header.onPress ? header.onPress(item) : null
                          }
                          src={text}
                          style={{
                            width: 100,
                            height: 50,
                            borderRadius: 8,
                            border: "2px solid #777",
                            aspectRatio: 3 / 2,
                            objectFit: "cover",
                            cursor: "pointer",
                          }}
                        />
                      </div>
                    ) : header.type === "link" ? (
                      <div>
                        <Link
                          className="text-primary text-hover-decoration text-overflow cell-width"
                          onClick={() =>
                            header.onPress ? header.onPress(item) : null
                          }
                          to="#"
                          title={!returnMultiAnswer(item, header.key) && text}
                          style={header.styles || {}}
                        >
                          {capitalizeText(text) + ""}
                        </Link>
                      </div>
                    ) : header.type === "timeAgo" ? (
                      <div className="text-overflow cell-width">
                        {text.replaceAll(" ", "") === "-" ? (
                          <span>-</span>
                        ) : (
                          <TimeAgo
                            date={moment(text.replaceAll(" ", "")).format(
                              "lll"
                            )}
                          />
                        )}
                      </div>
                    ) : header.type === "status" ? (
                      <div className={`badge badge-${text.toLowerCase()}`}>
                        <span>{capitalizeText(text) + ""}</span>
                      </div>
                    ) : (
                      <div
                        onClick={() =>
                          header.key !== "action" && rowPress
                            ? rowPress(item, keyRowIndex)
                            : null
                        }
                        title={!returnMultiAnswer(item, header.key) && text}
                      >
                        {returnMultiAnswer(item, header.key) ? (
                          returnMultiAnswer(item, header.key)
                        ) : text?.replace(" ", "")?.toLowerCase() === "true" ? (
                          <div className={`status-icon-container success`}>
                            <span className={process.env.REACT_APP_ICONS_TYPE}>
                              {icons.success}
                            </span>
                          </div>
                        ) : text?.replace(" ", "")?.toLowerCase() ===
                          "false" ? (
                          <div className={`status-icon-container failed`}>
                            <span className={process.env.REACT_APP_ICONS_TYPE}>
                              {icons.fail}
                            </span>
                          </div>
                        ) : typeof text === "string" && isJsonString(text) ? (
                          <>{JSONStringRowView(text)}</>
                        ) : (
                          <span
                            className="text-overflow cell-width"
                            style={header.styles || {}}
                          >
                            {header.isMoment ? text : capitalizeText(text) + ""}
                          </span>
                        )}
                      </div>
                    )}
                  </td>
                )}
              </>
            );
          })}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  const { notifications } = state.Notifications;
  return {
    defaultLanguage,
    notifications,
  };
};

export default connect(mapStateToProps)(Row);
