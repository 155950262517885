import "./styles.css";
import LeftContent from "./LeftContent";
import ImageContent from "./ImageContent";

const Introduction = (props) => {
  return (
    <>
      <div className="row d-flex flex-wrap">
        <LeftContent />
        <ImageContent />
      </div>
    </>
  );
};

export default Introduction;
