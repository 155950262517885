import React from "react";
import { connect } from "react-redux";
import "./styles.css";
import language from "../../../language";
import icons from "../../../constants/icons";
import { Button } from "../../Button";

const list = [
  {
    icon: "add_notes",
    title: "Apply for Grant & Track Business Act.",
    number: "01",
    backgroundColor: `linear-gradient(
      256deg,
      #e5fcee 39.92%,
      rgb(218, 220, 210, 2) 100%
    )`,
    content: {
      title: "Apply for Grant & Track your business Activities",
      description:
        "Lorem ipsum dolor sit amet consectetur. Quis curabitur suspendisse ullamcorper enim in et sit velit. Arcu cras faucibus interdum metus in.",
      button: "EXPLORE",
      buttonIcon: "arrow_right",
    },
  },
  {
    icon: "add_notes",
    title: "Analytics of Grant Usage",
    number: "02",
    backgroundColor: `linear-gradient(253deg, #FEFAEF 38.16%, rgba(252, 252, 252, 0.00) 105.41%)`,
  
    content: {
        title: "Analytics of Grant Usage",
        description:
          "Lorem ipsum dolor sit amet consectetur. Quis curabitur suspendisse ullamcorper enim in et sit velit. Arcu cras faucibus interdum metus in.",
        button: "EXPLORE",
        buttonIcon: "arrow_right",
      },
},
  {
    icon: "add_notes",
    title: "End to End Communication",
    number: "03",
    backgroundColor: `linear-gradient(259deg, #FEFAEF 21.86%, #EAFBFF 103.82%)`,
    content: {
        title: "End to End Communication",
        description:
          "Lorem ipsum dolor sit amet consectetur. Quis curabitur suspendisse ullamcorper enim in et sit velit. Arcu cras faucibus interdum metus in.",
        button: "EXPLORE",
        buttonIcon: "arrow_right",
      },

  },
  {
    icon: "add_notes",
    title: "Daily Reporting",
    number: "04",
    backgroundColor: `linear-gradient(256deg, #F9F1FC 39.92%, rgba(252, 252, 252, 0.00) 100%)`,
    content: {
        title: "Daily Reporting",
        description:
          "Lorem ipsum dolor sit amet consectetur. Quis curabitur suspendisse ullamcorper enim in et sit velit. Arcu cras faucibus interdum metus in.",
        button: "EXPLORE",
        buttonIcon: "arrow_right",
      },

},
];

class Process extends React.Component {
  state = {
    selectedList: list[0],
  };

  setSelectedList(item) {
    this.setState({ selectedList: item });
  }

  render() {
    const props = this.props;
    return (
      <div className={`process-container row ${props.className}`}>
        <div className="process-header">
          <h5 className="text-bold">Benefit of using our platform</h5>
          <p>
            Lorem ipsum dolor sit amet consectetur. Quis curabitur suspendisse
            ullamcorper enim in et sit velit. Arcu cras faucibus interdum metus
            in.
          </p>
        </div>
        <div className="row">
          <div className="col-md-5 pt-4">
            <div className="list-process-container">
              {list.map((item, i) => {
                return (
                  <div
                    key={i}
                    className={`process-item-container ${
                      this.state.selectedList.number === item.number ? "active" : ""
                    }`}
                    style={{ background: item.backgroundColor }}
                    onClick={() => this.setSelectedList(item)}
                  >
                    <div className="icon-container">
                      <span className={process.env.REACT_APP_ICONS_TYPE}>
                        {item.icon}
                      </span>
                    </div>
                    <div style={{ flex: 1 }}>
                      <h3>{item.title}</h3>
                    </div>
                    <div className="number">
                    <h2 className={this.state.selectedList.number === item.number ? "active-number" : ""}>{item.number}</h2>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="col-md-7">
            <div className="process-description-container d-flex flex-column">
              <div style={{ flex: 1 }}>
                <h2
                  className="text-light text-bold "
                  style={{ marginBottom: "2rem" }}
                >
                  {this.state.selectedList.content.title}
                </h2>
                <p style={{ marginBottom: "2rem" }}>
                  {this.state.selectedList.content.description}
                </p>
                <Button
                  className="btn-transparent light"
                  text={this.state.selectedList.content.button}
                  icon={this.state.selectedList.content.buttonIcon}
                />
              </div>
              <div className="process-description-container-footer "></div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(Process);
