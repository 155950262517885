import React from "react";
import Accounts from "../../components/Accounts/Accounts";
import language from "../../language";
import { connect } from "react-redux";
import { Tabs } from "../../components/Tabs";
import { defineRole } from "../../utils/handleAccessRoles";
import { getStorage } from "../../utils/storage";
import { AccessRoles } from "../../components/AccessRole";

class AccountScreen extends React.Component {
  state = {
    user: {},
  };

  componentDidMount = async () => {
    const user = await getStorage();

    this.setState({ user });
  };

  returnTabsOptions = () => {
    const { user } = this.state;
    const { accessRole } = user;

    const canReadActive = defineRole({
      roles: accessRole,
      menu: "Active Accounts",
      operation: "read",
    });

    const canReadInactive = defineRole({
      roles: accessRole,
      menu: "Inactive Accounts",
      operation: "read",
    });

    const canReadAccessRole = defineRole({
      roles: accessRole,
      menu: "Access Role",
      operation: "read",
    });

    let tabsOptions = [];

    if (canReadActive) {
      tabsOptions.push({
        title: language[this.props.defaultLanguage].active_accounts,
        data: <Accounts isActive />,
      });
    }

    if (canReadInactive) {
      tabsOptions.push({
        title: language[this.props.defaultLanguage].inactive_accounts,
        data: <Accounts isActive={false} />,
      });
    }

    if (canReadAccessRole) {
      tabsOptions.push({
        title: language[this.props.defaultLanguage].access_roles,
        data: <AccessRoles />,
      });
    }

    return tabsOptions;
  };

  render() {
    return (
      <>
        <div className="card card-dashboard-content">
          <div className="card-body">
            <Tabs options={this.returnTabsOptions()} />
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(AccountScreen);
