import React from "react";
import "../styles.css";
import CardCount from "../../CardCount/CardCount";
import { fetchCounts } from "../../../utils/queries/analyticsQuery";
import { getStorage } from "../../../utils/storage";
import { connect } from "react-redux";
import formateFilters from "../../../utils/formateFilters";
import { homeCardCounts } from "../../../constants/cardCounts";
import icons from "../../../constants/icons";

class Counts extends React.Component {
  state = {
    cardCounts: homeCardCounts,
    isLoading: true,
  };
  componentDidMount = async () => {
    this.handleFetchData();
  };

  handleFetchData = async () => {
    const user = await getStorage();
    await this.setState({ user });

    const { service = {} } = this.props.filters;
    const { settings = {} } = service;

    if (settings && settings?.cardCounts?.length > 0) {
      this.fetchCountsPerService(settings.cardCounts);
    } else {
      console.log("I  amshsjdsjdhs");
      //   this.fetchGeneralCounts();
      this.fetchCountsPerService([
        {
          label: "Total FSCs",
          value: "totalFSCs",
          icon: "face",
        },
        {
          label: "Total Farmers",
          value: "totalFarmers",
          icon: "contacts",
        },
        {
          label: "Off-farmers Actors",
          value: "totalOffFarmersActors",
          icon: icons.users,
        },
        {
          label: "Total Market Actors",
          value: "totalMarketActors",
          icon: icons.users,
        },
      ]);
    }
  };

  //   fetchGeneralCounts = () => {
  //     this.getCounts(true, {
  //       fieldName: "totalFSCs",
  //       ["service.type"]: ["FSCs"],
  //     });

  //     this.getCounts(true, {
  //       fieldName: "totalIndividualFarmers",
  //       ["service.type"]: "FARMERS",
  //       //   [
  //       //     "INDIVIDUAL_FARMER_REGISTRATION",
  //       //     "INDIVIDUAL_YOUNG_FARMER",
  //       //     "YOUNG_FARMER_DEMOGRAPHY",
  //       //     "DEMOGRAPHY",
  //       //   ],
  //     });

  //     this.getCounts(true, {
  //       fieldName: "totalMarketActorsFarmers",
  //       ["service.type"]: ["MARKET_ACTORS_REGISTRATION"],
  //     });

  //     this.getCounts(true, {
  //       fieldName: "totalOffFarm",
  //       ["service.type"]: ["OFF_FARM_REGISTRATION"],
  //     });
  //   };

  fetchCountsPerService(cardCounts = []) {
    this.setState({ cardCounts });
    const { isLoading } = this.state;

    cardCounts.forEach((cardCount) => {
      if (cardCount.value === "totalYouthCounts") {
        this.getCounts(isLoading, {
          fieldName: "totalYouthCounts",
          ["startQuestionnaireAnswer.answer"]: "18",
          ["endQuestionnaireAnswer.answer"]: "35",
          count: true,
        });
      }

      if (cardCount.value === "totalMaleYouthCounts") {
        this.getCounts(isLoading, {
          fieldName: "totalMaleYouthCounts",
          ["questionnaireAnswer.questionText"]: ["Gender", "gender"],
          ["questionnaireAnswer.answer"]: ["Male", "M", "male"],
          count: true,
        });
      }

      if (cardCount.value === "totalFemaleYouthCounts") {
        this.getCounts(isLoading, {
          fieldName: "totalFemaleYouthCounts",
          ["questionnaireAnswer.questionText"]: ["Gender", "gender"],
          ["questionnaireAnswer.answer"]: ["Female", "F", "female"],
          count: true,
        });
      }

      if (cardCount.value === "totalNonYouthCounts") {
        this.getCounts(isLoading, {
          fieldName: "totalNonYouthCounts",
          ["startQuestionnaireAnswer.answer"]: 36,
          ["endQuestionnaireAnswer.answer"]: 150,
          count: true,
        });
      }

      if (cardCount.value === "total") {
        this.getCounts(isLoading, {
          fieldName: "total",
          count: true,
        });
      }

      if (cardCount.value === "totalFSCs") {
        this.getCounts(isLoading, {
          fieldName: "totalFSCs",
          ["service.type"]: "FSCs",
          count: true,
          location: "district",
          ["questionnaireAnswer.questionText"]: [
            "gender",
            "Gender",
            "gender ",
            "Gender ",
          ],
          ["questionnaireAnswer.answer"]: [
            "Female",
            "Female ",
            "F",
            "f",
            "female",
            "female ",
            "Male",
            "Male ",
            "MALE ",
            "M",
            "m",
            "male",
            "male ",
          ],
        });
      }

      if (cardCount.value === "totalFarmers") {
        this.getCounts(isLoading, {
          fieldName: "totalFarmers",
          ["service.type"]: "FARMERS",
          count: true,
          location: "district",
          ["questionnaireAnswer.questionText"]: [
            "gender",
            "Gender",
            "gender ",
            "Gender ",
            "GENDER ",
            "GENDER",
          ],
          ["questionnaireAnswer.answer"]: [
            "Female",
            "Female ",
            "F",
            "f",
            "female",
            "female ",
            "Male",
            "Male ",
            "MALE ",
            "M",
            "m",
            "male",
            "male ",
          ],
        });
      }

      if (cardCount.value === "totalOffFarmersActors") {
        this.getCounts(isLoading, {
          fieldName: "totalOffFarmersActors",
          ["service.type"]: "IMPACT",
          count: true,
          location: "district",
          ["questionnaireAnswer.questionText"]: [
            "gender",
            "Gender",
            "GENDER ",
            "GENDER",
            "gender ",
            "Gender ",
          ],
          ["questionnaireAnswer.answer"]: [
            "Female",
            "Female ",
            "F",
            "f",
            "female",
            "female ",
            "Male",
            "Male ",
            "MALE ",
            "M",
            "m",
            "male",
            "male ",
          ],
        });
      }

      if (cardCount.value === "totalMarketActors") {
        this.getCounts(isLoading, {
          fieldName: "totalMarketActors",
          ["service.type"]: "MARKET_ACTORS_REGISTRATION",
          count: true,
          location: "district",
          ["questionnaireAnswer.questionText"]: ["gender", "Gender"],
          ["questionnaireAnswer.answer"]: [
            "F",
            "f",
            "female",
            "Female",
            "M",
            "m",
            "male",
            "Male",
          ],
        });
      }
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.filters !== this.props.filters) {
      this.handleFetchData(this.props.filters?.settings?.cardCounts);
    }
  }

  getCounts = async (isLoading, params = {}) => {
    try {
      const { defaultLanguage } = this.props;

      this.setState({ [`is_${params.fieldName}`]: isLoading });

      let returnFilters = {
        ...formateFilters(this.props.filters),
        ...params,
      };

      delete returnFilters.startCreatedAt;
      delete returnFilters.endCreatedAt;

      const data = await fetchCounts(defaultLanguage, returnFilters);

      let count = 0;

      if (data.length > 0) {
        count = data[0].count;
      }

      this.setState({
        [params.fieldName]: count,
        [`is_${params.fieldName}`]: false,
      });
    } catch (error) {
      console.log(error);
      this.setState({
        [`is_${params.fieldName}`]: false,
        [params.fieldName]: 0,
      });
    }
  };

  handleToggle(isToggled) {
    this.setState({
      [isToggled]: !this.state[isToggled],
    });
  }

  render() {
    return (
      <>
        <div className="row mb-3">
          {this.state.cardCounts.map((el, i) => {
            return (
              <div
                className={`col-md-${
                  this.state.cardCounts.length === 3 ? 4 : 3
                }`}
                key={i}
              >
                <CardCount
                  icon={el.icon}
                  color="#00C850"
                  count={this.state[el.fieldName || el.value]}
                  title={el.title || el.label}
                  isLoading={this.state[`is_${el.fieldName || el.value}`]}
                />
              </div>
            );
          })}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  const { filters } = state.Filters;
  return {
    defaultLanguage,
    filters,
  };
};

export default connect(mapStateToProps)(Counts);
