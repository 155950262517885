import React from "react";

import { Login } from "../../../components/Login";
import language from "../../../language";
import { connect } from "react-redux";

class LoginScreen extends React.Component {
  render() {
    return (
      <>
        <Login
          type="admin"
          title={language[this.props.defaultLanguage].admin}
        />
      </>
    );
  }
}

const mapStateTopProps = (state) => {
  const { defaultLanguage } = state.Language;

  return {
    defaultLanguage,
  };
};

export default connect(mapStateTopProps)(LoginScreen);
