import React from "react";
import { ListItem } from "../List";
import { Input } from "../Input";
import { Loading } from "../Loader";
import icons from "../../constants/icons";
import language from "../../language";
import { connect } from "react-redux";
import { Button } from "../Button";
import { Modal } from "../Modal";
import handleSearch from "../../utils/handleSearch";
import ServicesModal from "./ServicesModal";
import Empty from "../Empty";

let copyData = [];

class ListViewServices extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: props.data,
    };
  }

  getData() {
    this.setState({
      data: this.props.data,
    });
    copyData = this.props.data.slice(0);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data) {
      this.getData();
    }
  }

  onChangeText(field, e) {
    let { error } = this.state;

    delete error[field];

    this.setState({
      [field]: e.target.value,
      error,
    });
  }

  handlePagination(page) {
    this.setState(
      {
        page,
      },
      () => {
        this.getData(true);
      }
    );
  }

  handleShowModal(modal) {
    this.setState({
      [modal]: true,
    });
  }

  handleCloseModal(modal) {
    this.setState({
      [modal]: false,
    });
  }

  render() {
    return (
      <>
        <div>
          <div className="mb-3">
            <Input
              placeholder={language[this.props.defaultLanguage].search}
              leftIcon={icons.search}
              value={this.state.searchText}
              onChange={(e) =>
                handleSearch(this, {
                  copyData,
                  value: e.target.value,
                })
              }
            />
            <Button
              text={language[this.props.defaultLanguage].add_new}
              icon={icons.add}
              onPress={() => this.handleShowModal("showServicesModal")}
            />
          </div>
          {this.props.isLoading ? (
            <Loading className="loader-fullscreen" />
          ) : this.state.data.length > 0 ? (
            <div className="row">
              {this.props.data.map((el, i) => {
                return (
                  <div className="col-md-4 mb-2" key={i}>
                    <div className="card">
                      <div className="card-body">
                        <ListItem
                          name={el.name}
                          rightIcon={icons.chevronRight}
                          onPress={() =>
                            (window.location.href = `/user/services/${el._id}/${el.name}`)
                          }
                          titleStyles={{
                            textTransform: "uppercase",
                            fontWeight: "700",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <Empty title={language[this.props.defaultLanguage].no_data} />
          )}
        </div>
        <Modal
          handleClose={this.handleCloseModal.bind(this, "showServicesModal")}
          show={this.state.showServicesModal}
          showHeaderBottomBorder={false}
          size="lg"
          title={this.state.modalTitle}
        >
          <ServicesModal />
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(ListViewServices);
