import React from "react";
import "./styles.css";
import { Link } from "react-router-dom";
import icons from "../../constants/icons";

class Breadcrumb extends React.Component {
  render() {
    return (
      <>
        {this.props?.data?.length > 0 && (
          <div className="breadcrumb-nav-container">
            <div
              className="icon-container gray sm"
              onClick={() => (window.location.href = this.props.homeUrl)}
            >
              <span className={process.env.REACT_APP_ICONS_TYPE}>
                {icons.home}
              </span>
            </div>
            <ol className="breadcrumb">
              {this.props?.data?.map((el, i) => {
                return (
                  <li
                    className={`breadcrumb-item  ${el.active && "active"}`}
                    key={i}
                  >
                    <div className="breadcrumb-item-link">
                      {el.active ? (
                        <span className="breadcrumb-item-name text-primary">
                          {el.name}
                        </span>
                      ) : (
                        <Link to={`${el.url}`}>{el.name}</Link>
                      )}
                    </div>
                  </li>
                );
              })}
            </ol>
          </div>
        )}
      </>
    );
  }
}

export default Breadcrumb;
