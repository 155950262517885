import React from "react";
import { connect } from "react-redux";
import language from "../../language";
import { Table } from "../Table";
import serviceQuery from "../../utils/queries/serviceQuery";
import handleSearch from "../../utils/handleSearch";
import { getStorage } from "../../utils/storage";
import { onResetFilters } from "../../actions/Filters";
import ListViewServices from "./ListViewServices";

let copyData = [];
class Services extends React.Component {
  state = {
    isLoading: false,
    data: [],
    page: 1,
    limit: 50,
    search_text: "",
    selected_data: {},
    delete_value: "",
    error: {},
    csvData: [],
    user: {},
  };

  componentDidMount = async () => {
    const user = await getStorage();
    await this.setState({ user });
    this.getData(true);
  };

  componentDidUpdate(prevProps) {
    if (prevProps.routeCategory !== this.props.routeCategory) {
      //reset filters
      this.props.dispatch(onResetFilters());

      this.getData(true);
    }

    if (prevProps.filters !== this.props.filters) {
      this.getData(true);
    }
  }

  getData = async (isLoading) => {
    try {
      const { page, limit } = this.state;
      const { defaultLanguage, filters = {}, routeCategory } = this.props;
      const { categories } = filters;

      this.setState({ isLoading });

      const { data, count } = await serviceQuery(defaultLanguage, {
        page,
        limit,
        serviceType: routeCategory,
        categories,
      });

      this.setState({
        data,
        totalPageCount: count,
        isLoading: false,
        selectedCategory: {},
      });

      copyData = data?.slice(0) || [];
    } catch (error) {
      this.setState({
        isLoading: false,
      });
    }
  };

  onChangeText(field, e) {
    let { error } = this.state;

    delete error[field];

    this.setState({
      [field]: e.target.value,
      error,
    });
  }

  handlePagination(page) {
    this.setState(
      {
        page,
      },
      () => {
        this.getData(true);
      }
    );
  }

  handleShowModal(modal, modalTitle, selected_data = {}) {
    this.setState({
      [modal]: true,
      modalTitle: modalTitle,
      selected_data,
    });
  }

  handleCloseModal(modal) {
    this.setState({
      [modal]: false,
    });
  }

  returnTableHeaders() {
    let headers = [
      {
        title: language[this.props.defaultLanguage].service,
        key: "name",
        type: "link",
        styles: {
          textTransform: "uppercase",
        },
        onPress: (item) =>
          (window.location.href = `/${this.props.role}/service/surveys/${item?._id}/${item.name}/${item.category._id}`),
      },
    ];

    return headers;
  }

  handleSelectCategory(item) {
    this.setState(
      {
        selectedCategory: item,
      },
      () => {
        this.getData(true);
      }
    );
  }

  render() {
    return (
      <>
        {this.props.type === "listview" ? (
          <ListViewServices
            data={this.state.data}
            isLoading={this.state.isLoading}
          />
        ) : (
          <Table
            data={this.state.data}
            isSearch
            page={this.state.page}
            limit={this.state.limit}
            search_text={this.state.search_text}
            totalPageCount={this.state.totalPageCount}
            isLoading={this.state.isLoading}
            handlePagination={this.handlePagination.bind(this)}
            headers={this.returnTableHeaders()}
            handleSearch={(e) =>
              handleSearch(this, {
                copyData,
                value: e.target.value,
                searchKey: "name",
              })
            }
            rowPress={(item) =>
              (window.location.href = `/${this.props.role}/surveys/${item?._id}`)
            }
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  const { filters } = state.Filters;
  return {
    defaultLanguage,
    filters,
  };
};

export default connect(mapStateToProps)(Services);
