import React from "react";
import "./styles.css";
import TableHeader from "./TableHeader";
import TableFooter from "./TableFooter";

let copyData = [];

class Wrapper extends React.Component {
  render() {
    return (
      <>
        <div>
          <TableHeader {...this.props} handleSearch={this.props.handleSearch} />
          <div className={`table-responsive`}>
            {React.Children.map(this.props.children, (child) =>
              React.cloneElement(child, {
                ...this.props,
                ...this.state,
              })
            )}
          </div>

          <TableFooter {...this.props} {...this.state} />
        </div>
      </>
    );
  }
}

export default Wrapper;
