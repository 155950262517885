import React from "react";
import { connect } from "react-redux";
import language from "../../../language";
import { Table } from "../../Table";
import { DeleteModal, Modal } from "../../Modal";
import NewRequiredDocument from "./NewRequiredDocument";
import { CSVLink } from "react-csv";
import serviceQuery from "../../../utils/queries/serviceQuery";
import handleSearch from "../../../utils/handleSearch";
import toastMessage from "../../../utils/toastMessage";
import { getStorage } from "../../../utils/storage";
import { defineRole } from "../../../utils/handleAccessRoles";
import { apiInstance } from "../../../utils/axiosInstance";

let copyData = [];
class RequiredDocuments extends React.Component {
  state = {
    isLoading: false,
    data: {},
    page: 1,
    limit: 10,
    search_text: "",
    selected_data: {},
    delete_password: "",
    error: {},
    csvData: [],
    user: {},
  };

  componentDidMount = async () => {
    const user = await getStorage();

    this.setState({ user });
    this.getData(true);
  };

  getData = async (isLoading) => {
    try {
      const { page, limit } = this.state;
      const { defaultLanguage, serviceId } = this.props;

      this.setState({ isLoading });

      const { data, count } = await serviceQuery(defaultLanguage, {
        page,
        limit,
        id: serviceId,
      });

      this.setState({
        data: data.length > 0 ? data[0] : {},
        totalPageCount: count,
        isLoading: false,
      });

      copyData = data?.files?.slice(0) || [];
    } catch (error) {
      this.setState({
        isLoading: false,
      });
    }
  };

  onChangeText(field, e) {
    let { error } = this.state;

    delete error[field];

    this.setState({
      [field]: e.target.value,
      error,
    });
  }

  handlePagination(page) {
    this.setState(
      {
        page,
      },
      () => {
        this.getData(true);
      }
    );
  }

  handleShowModal(modal, modalTitle, selected_data = {}) {
    this.setState({
      [modal]: true,
      modalTitle: modalTitle,
      selected_data,
    });
  }

  handleCloseModal(modal) {
    this.setState({
      [modal]: false,
    });
  }

  returnTableHeaders() {
    let headers = [
      {
        title: language[this.props.defaultLanguage].required_document_name,
        key: "name",
      },
      {
        title: language[this.props.defaultLanguage].actions,
        key: "action",
      },
    ];

    return headers;
  }

  onDelete = async () => {
    try {
      this.setState({ isDeleting: true });

      let { selected_data, data } = this.state;
      let { files } = data;
      let user = await getStorage();

      files.splice(selected_data.dataIndex, 1);

      this.setState({ data: this.state.data });

      const options = {
        method: "PUT",
        url: `/service`,
        data: {
          id: data._id,
          files,
        },
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      };

      await apiInstance(options);

      toastMessage(
        "success",
        language[this.props.defaultLanguage].delete_required_document_success
      );

      this.setState({ isDeleting: false });

      this.handleCloseModal("showDeleteModal");
      this.getData();
    } catch (error) {
      this.setState({
        isDeleting: false,
      });

      toastMessage(
        "error",
        language[this.props.defaultLanguage].error_delete_required_document
      );
    }
  };

  render() {
    const {
      add_document,
      edit_document,
      delete_document,
      required_document,
      delete_,
      edit,
    } = language[this.props.defaultLanguage];
    return (
      <>
        <Table
          data={this.state?.data?.files || []}
          isSearch
          page={this.state.page}
          limit={this.state.limit}
          search_text={this.state.search_text}
          handleSearch={(e) =>
            handleSearch(this, {
              copyData,
              value: e.target.value,
            })
          }
          totalPageCount={this.state.totalPageCount}
          isLoading={this.state.isLoading}
          handlePagination={this.handlePagination.bind(this)}
          headers={this.returnTableHeaders()}
          showAdd
          addButtonText={add_document}
          handleAddPressed={this.handleShowModal.bind(
            this,
            "showModal",
            required_document
          )}
          actions={[
            {
              canAccess: defineRole({
                roles: this.state.user.accessRole,
                menu: "Services",
                operation: "update",
              }),
              name: edit,
              onPress: (item, index) =>
                this.handleShowModal("showModal", edit_document, {
                  ...item,
                  dataIndex: index,
                }),
            },
            {
              canAccess: defineRole({
                roles: this.state.user.accessRole,
                menu: "Services",
                operation: "delete",
              }),
              name: delete_,
              onPress: (item, index) =>
                this.handleShowModal("showDeleteModal", delete_document, {
                  ...item,
                  dataIndex: index,
                }),
            },
          ]}
        />
        <Modal
          handleClose={this.handleCloseModal.bind(this, "showModal")}
          show={this.state.showModal}
          title={this.state.modalTitle}
          showHeaderBottomBorder={false}
        >
          <NewRequiredDocument
            handleCloseModal={this.handleCloseModal.bind(this, "showModal")}
            getData={this.getData.bind(this)}
            serviceData={this.state.data}
            {...this.state.selected_data}
          />
        </Modal>

        <Modal
          handleClose={this.handleCloseModal.bind(this, "showDeleteModal")}
          show={this.state.showDeleteModal}
          title={this.state.modalTitle}
        >
          <DeleteModal
            handleCloseModal={this.handleCloseModal.bind(
              this,
              "showDeleteModal"
            )}
            error={this.state.error.delete_password}
            value={this.state.delete_value}
            onDelete={this.onDelete.bind(this)}
            onChange={(e) => this.onChangeText("delete_value", e)}
            isDeleting={this.state.isDeleting}
            name={this.state.selected_data.name}
            getData={this.getData.bind(this)}
          />
        </Modal>
        <CSVLink
          ref="csvDownload"
          filename={"Required Documents" + new Date().getTime()}
          data={this.state.csvData}
        ></CSVLink>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(RequiredDocuments);
