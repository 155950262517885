import React from "react";
import { connect } from "react-redux";
import language from "../../language";
import { Table } from "../Table";
import { CSVLink } from "react-csv";
import handleSearch from "../../utils/handleSearch";
import { getStorage } from "../../utils/storage";
import moment from "moment";
import dynamicTableData from "../../utils/dynamicTableData";
import formateFilters from "../../utils/formateFilters";
import surveyQuery from "../../utils/queries/surveysQuery";
import handleFormateDataCSV from "../../utils/handleFormateDataCSV";
import { Select } from "../Input";
import icons from "../../constants/icons";

let copyData = [];

class Locations extends React.Component {
  state = {
    isLoading: false,
    data: [],
    page: 1,
    limit: 50,
    search_text: "",
    selectedData: {},
    error: {},
    csvData: [],
    user: {},
    totalPageCount: 0,
    tableHeaders: [],
    location: ["province"],
  };

  componentDidMount = async () => {
    const user = await getStorage();
    await this.setState({ user });
    await this.getData(true);
  };

  componentDidUpdate(prevProps) {
    if (prevProps.filters !== this.props.filters) {
      this.getData(true);
    }
  }

  onChangeText(field, e) {
    let { error } = this.state;

    delete error[field];

    this.setState({ error, [field]: e.target.value });
  }

  returnFilters() {
    const { page, limit } = this.state;
    const { serviceId, surveyId, filters } = this.props;

    let _filters = {
      page,
      limit,
      ...formateFilters(filters),
      ["questionnaireAnswer.answerType"]: [
        "Latitude",
        "Longitude",
        "latitude",
        "longitude",
      ],
    };

    if (serviceId) {
      _filters["service._id"] = [serviceId];
    }

    if (surveyId) {
      _filters.id = surveyId;

      delete _filters["service._id"];
    }

    return _filters;
  }

  getData = async (isLoading) => {
    try {
      const { page, limit } = this.state;
      const { defaultLanguage } = this.props;

      this.setState({ isLoading });

      const { data, count } = await surveyQuery(defaultLanguage, {
        page,
        limit,
        ...this.returnFilters(),
      });

      const { tableHeaders, rowData } = dynamicTableData(this, data, [
        "province",
        "district",
        "sector",
        "cell",
        "village",
        "latitude",
        "longitude",
      ]);

      console.log({ rowData, tableHeaders, data });

      this.setState({
        data: rowData,
        tableHeaders,
        totalPageCount: count,
        isLoading: false,
      });

      copyData = rowData.slice(0);
    } catch (error) {
      this.setState({
        isLoading: false,
      });
    }
  };

  handlePagination(page) {
    this.setState(
      {
        page,
      },
      () => {
        this.getData(true);
      }
    );
  }

  downloadExcel = async () => {
    try {
      this.setState({
        isLoading: true,
      });

      const { defaultLanguage } = this.props;

      const { data } = await surveyQuery(defaultLanguage, this.returnFilters());

      const { tableHeaders } = dynamicTableData(this, data);

      let formateData = [];
      let headers = tableHeaders.map((el) => el.title);

      data.forEach((el) => {
        formateData.push(handleFormateDataCSV(el.questionnaireAnswer, headers));
      });

      this.setState(
        {
          isLoading: false,
          csvData: [headers, ...formateData],
        },
        () => this.refs.csvDownload?.link.click()
      );
    } catch (error) {
      this.setState({
        isLoading: false,
      });
    }
  };

  render() {
    const { export_ } = language[this.props.defaultLanguage];
    return (
      <>
        <Table
          data={this.state.data}
          isSearch
          page={this.state.page}
          limit={this.state.limit}
          search_text={this.state.search_text}
          handleSearch={(e) =>
            handleSearch(this, {
              copyData,
              value: e.target.value,
            })
          }
          totalPageCount={this.state.totalPageCount}
          isLoading={this.state.isLoading}
          handlePagination={this.handlePagination.bind(this)}
          headers={this.state.tableHeaders}
          rowPress={(item) =>
            this.handleShowModal("showModal", item.name, item)
          }
          filters={[
            {
              type: "export",
              title: language[this.props.defaultLanguage].location,
              button_type: "dropdown",
              icon: icons.location,
              filterName: "location",
            },
            {
              type: "export",
              title: language[this.props.defaultLanguage].date,
              button_type: "dropdown",
              icon: icons.calendar,
              filterName: "date_range",
            },

            {
              type: "export",
              title: export_,
              button_type: "dropdown",
              icon: "download",
              options: [
                {
                  name: "CSV",
                  onPress: this.downloadExcel.bind(this),
                },
              ],
            },
          ]}
        />
        <CSVLink
          ref="csvDownload"
          filename={
            language[this.props.defaultLanguage].locations +
            " - " +
            moment().format("lll")
          }
          data={this.state.csvData}
        ></CSVLink>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  const { filters } = state.Filters;
  return {
    defaultLanguage,
    filters,
  };
};

export default connect(mapStateToProps)(Locations);
