import React from "react";
import { connect } from "react-redux";
import MultiAnswer from "./MultiAnswer";
import language from "../../language";
import RemoteDropdown from "./dynamicForm/RemoteDropdown";
import SingleInput from "./dynamicForm/SingleInput";
import { Button } from "../Button";
import icons from "../../constants/icons";

class Questionnaire extends React.Component {
  state = {
    isRetrievingUserInfo: false,
    profileData: {},
    forms: [],
  };

  componentDidMount() {
    const { form } = this.props;

    let forms = [form];

    this.setState({ forms });
  }

  handleDuplicateForm = async (e) => {
    e.preventDefault();
    let { forms } = this.state;
    const { form } = this.props;

    forms.push(form);

    await this.setState({
      forms,
    });

    this.props.updateNumberForms(forms.length);
  };

  handleDeleteForm(index) {
    let { forms } = this.state;

    forms.splice(index, 1);

    this.setState({
      forms,
    });
  }

  renderFormFooter(actions) {
    return (
      <div className={`d-flex gap-2`}>
        {actions.map((action, i) => {
          return (
            <Button
              key={i}
              className={`btn-default btn-${action.variant} btn-sm`}
              text={action.name}
              icon={action.icon}
              onPress={action.onPress}
            />
          );
        })}
      </div>
    );
  }

  render() {
    return (
      <div>
        <div className="card-header default">
          <div className="d-flex align-items-center gap-2 flex-wrap">
            <div className="flex-fill">
              <h3 className="text-primary">
                {
                  language[this.props.defaultLanguage]
                    .please_fill_the_form_below
                }
              </h3>
              <p>
                All field with <span className="text-danger">(*)</span> are
                mandatory
              </p>
            </div>
          </div>
        </div>
        {this.state?.forms.map((parentForm, parentKey) => {
          return (
            <div key={parentKey}>
              <div className="row card-body">
                {this.props?.form?.map((el, inputIndex) => {
                  if (!el?.singleAnswer?.available) return;

                  if (
                    el.answerType === "multipleChoice" ||
                    el.answerType === "multipleAnswer"
                  ) {
                    return (
                      <>
                        <MultiAnswer
                          formKey={parentKey}
                          inputIndex={inputIndex}
                          {...this.props}
                          multiAnswer={el.multiAnswer}
                          questionText={el.questionText}
                          questionId={el._id}
                          answerType={el.answerType}
                          singleAnswer={el.singleAnswer}
                          onChange={this.props.onChange}
                          value={
                            this.props.questionnaireAnswer[parentKey] &&
                            this.props.questionnaireAnswer[parentKey][el._id]
                          }
                          error={this.props.error[el._id + "_" + parentKey]}
                          required={el?.singleAnswer?.required}
                        />
                      </>
                    );
                  }

                  if (el.answerType === "integration" && el.singleAnswer) {
                    return (
                      <div className="col-md-3">
                        <RemoteDropdown
                          formKey={parentKey}
                          questionText={el.questionText}
                          {...el.singleAnswer}
                          label={el.questionText}
                          responseKey={el.responseKey}
                          {...this.props}
                          questionId={el._id}
                          answerType={el.answerType}
                          onChange={this.props.onChange}
                          value={
                            this.props.questionnaireAnswer[parentKey] &&
                            this.props.questionnaireAnswer[parentKey][el._id]
                          }
                          error={this.props.error[el._id + "_" + parentKey]}
                          required={el?.singleAnswer?.required}
                        />
                      </div>
                    );
                  }
                  return (
                    <SingleInput
                      formKey={parentKey}
                      {...this.props}
                      questionText={el.questionText}
                      questionId={el._id}
                      answerType={el.answerType}
                      onChange={this.props.onChange}
                      value={
                        this.props.questionnaireAnswer[parentKey] &&
                        this.props.questionnaireAnswer[parentKey][el._id]
                      }
                      error={this.props.error[el._id + "_" + parentKey]}
                      required={el?.singleAnswer?.required}
                    />
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(Questionnaire);
