import React from "react";
import { withRouter } from "../../utils/withRouter";
import { Signup } from "../../components/Signup";

class SignupScreen extends React.Component {
  render() {
    return (
      <>
        <Signup serviceId={this.props?.routeParams?.serviceId} />
      </>
    );
  }
}

export default withRouter(SignupScreen);
