import LandingPageLayout from "./LandingPageLayout";
import HomeAdminDashboardLayout from "./HomeAdminDashboardLayout";
import HomeUserDashboardLayout from "./HomeUserDashboardLayout";
import HomeAnalyticsLayout from "./HomeAnalyticsLayout";
import CenterLayout from "./CenterLayout";

export {
  LandingPageLayout,
  HomeAdminDashboardLayout,
  HomeAnalyticsLayout,
  CenterLayout,
  HomeUserDashboardLayout,
};
