// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.factorAuthentication-container {
  /* display: flex;
  align-items: center;
  justify-content: center; */
}

.factorAuthentication-container .vector-container {
  width: 180px;
  height: 180px;
}

.factorAuthentication-container .vector-container img {
  width: 100%;
  aspect-ratio: 3/2;
}
`, "",{"version":3,"sources":["webpack://./src/app/components/Security/styles.css"],"names":[],"mappings":"AAAA;EACE;;4BAE0B;AAC5B;;AAEA;EACE,YAAY;EACZ,aAAa;AACf;;AAEA;EACE,WAAW;EACX,iBAAiB;AACnB","sourcesContent":[".factorAuthentication-container {\r\n  /* display: flex;\r\n  align-items: center;\r\n  justify-content: center; */\r\n}\r\n\r\n.factorAuthentication-container .vector-container {\r\n  width: 180px;\r\n  height: 180px;\r\n}\r\n\r\n.factorAuthentication-container .vector-container img {\r\n  width: 100%;\r\n  aspect-ratio: 3/2;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
