import React from "react";
import HorizontalBarChart from "../HorizontalBarChart";
import surveyQuery from "../../../utils/queries/surveysQuery";
import { Loading } from "../../Loader";
import { connect } from "react-redux";
import formateFilters from "../../../utils/formateFilters";
import language from "../../../language";
import capitalizeText from "../../../utils/capitalizeText";
import Empty from "../../Empty/Empty";
import isJsonString from "../../../utils/isJsonString";

const locations = [
  {
    label: "Province",
    value: "province",
  },
  {
    label: "District",
    value: "district",
  },
  {
    label: "Sector",
    value: "sector",
  },
  {
    label: "Cell",
    value: "cell",
  },
  {
    label: "Village",
    value: "village",
  },
];
class FarmerRegionGender extends React.Component {
  state = {
    data: [],
    isLoading: false,
    location: locations[0],
    gender: "all",
  };

  componentDidMount() {
    this.getFarmers(true);
  }

  componentDidUpdate(prevProps) {
    if (this.props.filters !== prevProps.filters) {
      this.getFarmers(true);
    }
  }

  returnFilters() {
    const { filters } = this.props;

    let _filters = {
      rank: true,
      order: "region",
      rank: true,
      questionText: "Gender",
      ...formateFilters(filters),
    };

    delete _filters.startCreatedAt;
    delete _filters.endCreatedAt;

    if (_filters.location) {
      _filters.region = _filters.location;
      delete _filters.location;
    }

    return _filters;
  }

  getFarmers = async (isLoading) => {
    this.setState({
      isLoading,
    });
    try {
      const { defaultLanguage } = this.props;

      const { data } = await surveyQuery(defaultLanguage, this.returnFilters());

      let counts = {};
      let labels = [];

      data.forEach((el) => {
        const formateLabel = [];

        el.regionGender.forEach((region) => {
          if (isJsonString(region)) {
            const convertLabel = JSON.parse(region);
            formateLabel.push(convertLabel.value);
          } else {
            formateLabel.push(region?.replace(/\s+/g, ""));
          }
        });

        const label = formateLabel.join("-");

        labels.push(label);

        if (!counts[label]) {
          counts[label] = 0;
        }

        counts[label] += el.count;
      });

      const chartData = {
        labels: labels,
        datasets: [
          {
            label:
              language[this.props.defaultLanguage].gender_per_region +
              " / " +
              capitalizeText(
                this.props?.filters?.location?.value ||
                  this.props?.filters?.location
              ),
            backgroundColor: "rgba(0, 200, 80, .2)",
            borderColor: "rgba(0, 200, 80, 1)",
            borderWidth: 2,
            hoverBackgroundColor: "rgba(0, 200, 80, .7)",
            hoverBorderColor: "rgba(0, 200, 80, 1)",
            data: Object.values(counts),
          },
        ],
      };

      this.setState({
        data: chartData,
        isLoading: false,
      });
    } catch (error) {
      console.log(error);
      this.setState({
        isLoading: false,
      });
    }
  };
  render() {
    return (
      <>
        {this.state.isLoading ? (
          <Loading className="loader-fullscreen" />
        ) : this.state.data?.labels?.length > 0 ? (
          <HorizontalBarChart data={this.state.data} />
        ) : (
          <Empty title={language[this.props.defaultLanguage].no_data} />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { filters } = state.Filters;
  const { defaultLanguage } = state.Language;
  return {
    filters,
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(FarmerRegionGender);
