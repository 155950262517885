import { connect } from "react-redux";
import { Input, Select } from "../Input";
import language from "../../language";
import React from "react";
import RemoteDropdown from "./dynamicForm/RemoteDropdown";
import NormalDropdown from "./dynamicForm/NormalDropdown";
import TextInput from "./dynamicForm/TextInput";
import isJsonString from "../../utils/isJsonString";

class MultiAnswer extends React.Component {
  state = {
    data: [],
  };

  componentDidMount() {
    this.setState({ data: this.props.multiAnswer });
  }

  componentDidUpdate(prevProps) {
    if (this.props.multiAnswer !== prevProps.multiAnswer) {
      this.setState({ data: this.props.multiAnswer });
    }
  }

  onChangeText(field, e) {
    const value = e.target ? e.target.value : e;

    this.setState({ [field]: value });

    this.props.onChange({
      questionText: this.props.questionText,
      value: e,
      formKey: this.props.formKey,
    });
  }

  render() {
    let value = this.props.value ? this.props.value.answer : "";

    if (
      this.props.value &&
      this.props.value.answer &&
      isJsonString(this.props.value.answer)
    ) {
      value = JSON.parse(this.props.value.answer);
    }

    const props = this.props;

    return (
      <>
        <div className="col-md-3">
          <Select
            label={props.questionText}
            options={this.state.data.map((el) => {
              return {
                label: el.name,
                value: el.name,
                ...el,
              };
            })}
            value={value}
            required={props.required}
            error={this.props.error}
            onChange={(e) =>
              this.props.onChange({
                questionText: props.questionText,
                value: e,
                formKey: props.formKey,
                questionId: props.questionId,
                answerType: props.answerType,
                inputIndex: props.inputIndex,
              })
            }
            isMulti={props?.singleAnswer?.allowMultipleSelection}
          />
        </div>
        {this.state?.selectValue?.option?.value === "integration" && (
          <div className="col-md-3">
            <RemoteDropdown
              questionText={props.questionText}
              label={this.state?.selectValue?.label}
              responseKey={this.state?.selectValue?.responseKey}
              {...this.props}
              {...(this.props?.singleAnswer || {})}
              onChange={(e) =>
                this.props.onChange({
                  questionText: props.questionText,
                  value: e,
                  formKey: props.formKey,
                  questionId: props.questionId,
                  answerType: props.answerType,
                })
              }
              error={this.props.error}
              required={props.required}
            />
          </div>
        )}

        {/* //add more inputs here for selection option */}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(MultiAnswer);
