import React from "react";
import { Marker, useMap } from "react-leaflet";
import L from "leaflet";
import AppPopup from "./AppPopup";
// import mapPin from "../../assets/map-pin.png";

export default function AppMarker(props) {
  const map = useMap();

  return (
    <div>
      <Marker
        icon={L.divIcon({
          className: "leaflet-data-marker",
          html: `<div class='app-marker'>
         <span>${props.marker.count}</span>
              </div>`,
        })}
        position={[props.marker.latitude, props.marker.longitude]}
        eventHandlers={{
          click: (e) => {
            map.flyTo(e.latlng);
          },
        }}
      >
        <AppPopup data={props.marker} />
      </Marker>
    </div>
  );
}
