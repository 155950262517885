// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-header {
  border-bottom: 1px solid var(--text-color-10);
}

.modal-footer {
  padding-right: 1rem !important;
}

.modal_title {
  font-weight: var(--font-weight-md);
  font-size: var(--font-size-md);
}

/* confirmation modal styles */
.confirmation-modal-header {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 80px;
}
.confirmation-modal-header .confirmation-modal-icon-container {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background-color: var(--text-color-10);
  display: flex;
  align-items: center;
  justify-content: center;
}

.confirmation-modal-header .confirmation-modal-icon-container span {
  font-size: 35px;
  color: var(--light-color);
}

.confirmation-modal-header .confirmation-modal-icon-container.success {
  background-color: var(--primary-color);
}

.confirmation-modal-header .confirmation-modal-icon-container.failed {
  background-color: var(--error-color);
}

.confirmation-modal-content {
  padding-bottom: 1rem;
}
`, "",{"version":3,"sources":["webpack://./src/app/components/Modal/styles.css"],"names":[],"mappings":"AAAA;EACE,6CAA6C;AAC/C;;AAEA;EACE,8BAA8B;AAChC;;AAEA;EACE,kCAAkC;EAClC,8BAA8B;AAChC;;AAEA,8BAA8B;AAC9B;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,gBAAgB;AAClB;AACA;EACE,YAAY;EACZ,WAAW;EACX,kBAAkB;EAClB,sCAAsC;EACtC,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,eAAe;EACf,yBAAyB;AAC3B;;AAEA;EACE,sCAAsC;AACxC;;AAEA;EACE,oCAAoC;AACtC;;AAEA;EACE,oBAAoB;AACtB","sourcesContent":[".modal-header {\r\n  border-bottom: 1px solid var(--text-color-10);\r\n}\r\n\r\n.modal-footer {\r\n  padding-right: 1rem !important;\r\n}\r\n\r\n.modal_title {\r\n  font-weight: var(--font-weight-md);\r\n  font-size: var(--font-size-md);\r\n}\r\n\r\n/* confirmation modal styles */\r\n.confirmation-modal-header {\r\n  display: flex;\r\n  align-items: center;\r\n  justify-content: center;\r\n  min-height: 80px;\r\n}\r\n.confirmation-modal-header .confirmation-modal-icon-container {\r\n  height: 50px;\r\n  width: 50px;\r\n  border-radius: 50%;\r\n  background-color: var(--text-color-10);\r\n  display: flex;\r\n  align-items: center;\r\n  justify-content: center;\r\n}\r\n\r\n.confirmation-modal-header .confirmation-modal-icon-container span {\r\n  font-size: 35px;\r\n  color: var(--light-color);\r\n}\r\n\r\n.confirmation-modal-header .confirmation-modal-icon-container.success {\r\n  background-color: var(--primary-color);\r\n}\r\n\r\n.confirmation-modal-header .confirmation-modal-icon-container.failed {\r\n  background-color: var(--error-color);\r\n}\r\n\r\n.confirmation-modal-content {\r\n  padding-bottom: 1rem;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
