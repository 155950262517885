import React from "react";
import { Tabs } from "../../components/Tabs";
import { connect } from "react-redux";
import language from "../../language";
import { MyProfile, ChangePassword } from "../../components/Profile";
import { TwoFactorAuthentication } from "../../components/Security";
import { RolesPermissions } from "../../components/AdminSettings";

class SettingScreen extends React.Component {
  render() {
    return (
      <>
        <div className="card card-dashboard-content">
          <div className="card-body">
            <Tabs
              options={[
                {
                  title: language[this.props.defaultLanguage].my_profile,
                  data: <MyProfile />,
                },
                {
                  title:
                    language[this.props.defaultLanguage].roles_and_permissions,
                  data: <RolesPermissions />,
                },
                {
                  title:
                    language[this.props.defaultLanguage].two_factor_tab_name,
                  data: (
                    <>
                      <TwoFactorAuthentication />
                    </>
                  ),
                },
                {
                  title: language[this.props.defaultLanguage].change_password,
                  data: <ChangePassword />,
                },
              ]}
            />
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(SettingScreen);
