export default [
  {
    label: "True",
    value: "true",
  },
  {
    label: "False",
    value: "false",
  },
];
