import { ON_ADD_MENUS, ON_UPDATE_MENUS } from "../actions/Sidebar";

const initialState = {
  menus: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ON_ADD_MENUS:
      return {
        ...state,
        menus: [...action.payload],
      };
    case ON_UPDATE_MENUS:
      state.menus[action.index] = action.menu;
      return {
        ...state,
        menus: [...state.menus],
      };
    default:
      return state;
  }
};
export default reducer;
