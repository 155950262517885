import isValidJSONString from "./isValidJSONString";

export default (that, data, requiredFields, others = {}) => {
  try {
    let tableHeaders = [];
    let headers = [];

    if (data?.length > 0) {
      (data[0]?.questionnaireAnswer || data[0]?.questionnaireAnswer)?.forEach(
        (el) => {
          if (
            !headers.includes(el.questionText) &&
            requiredFields &&
            requiredFields.length > 0 &&
            (requiredFields.includes(el.answerType) ||
              requiredFields.includes(el.questionText?.toLowerCase()))
          ) {
            let formateHeader = {
              title: el.questionText,
              key: el.questionText + ".value",
            };

            tableHeaders.push(formateHeader);

            headers.push(el.questionText);

            return;
          }
          if (!headers.includes(el.questionText) && !requiredFields) {
            let formateHeader = {
              title: el.questionText,
              key: el.questionText + ".value",
            };

            if (
              el.questionText.toLowerCase().includes("unique") &&
              (that.state.user?.accountType !== "account" ||
                that.state?.user?.accountType !== "user")
            ) {
              formateHeader["type"] = "link";

              formateHeader["styles"] = {
                textTransform: "uppercase",
              };

              formateHeader["onPress"] = (item) =>
                (window.location.href = `/dashboard/dn/${
                  item[el.questionText].title
                }/${item[el.questionText].value}`);
            }
            tableHeaders.push(formateHeader);

            headers.push(el.questionText);
          }
        }
      );
    }

    let rowData = {};

    console.log(headers);

    data?.forEach((el) => {
      el?.questionnaireAnswer &&
        el?.questionnaireAnswer?.forEach((answer) => {
          for (let [hIndex, elHeader] of headers.entries()) {
            if (answer.questionText === elHeader) {
              if (!rowData[answer.duplicateId]) {
                rowData[answer.duplicateId] = {};
              }

              let formattedAnswer = readAnswer(answer.answer);

              let originalFormattedAnswer = answer.answer;

              const splitAnswer = formattedAnswer.split("-SNID-");
              if (splitAnswer.length > 1) {
                formattedAnswer = splitAnswer[0];
              }

              rowData[answer.duplicateId][elHeader] = {
                title: elHeader,
                key: elHeader,
                value: formattedAnswer,
                _id: el._id,
                originalAnswer: originalFormattedAnswer,
                data: el,
                uniqueId: answer.duplicateId,
              };
            }
          }
        });
    });

    return {
      tableHeaders,
      rowData: Object.values(rowData),
    };
  } catch (error) {
    console.log("error occured while formatting table*****", error);
  }
};

const readAnswer = (answerInfo) => {
  try {
    if (Array.isArray(answerInfo)) {
      if (answerInfo.length === 1) {
        return answerInfo[0];
      } else {
        return answerInfo.length + " View";
      }
    }

    if (typeof answerInfo === "object") {
      if (answerInfo.label) {
        return answerInfo.label;
      } else {
        let res = answerInfo.value ? answerInfo.value : answerInfo.answer;
        return res;
      }
    }

    if (isValidJSONString(answerInfo) && JSON.parse(answerInfo)) {
      return readAnswer(JSON.parse(answerInfo));
    }

    if (answerInfo.label) {
      return answerInfo.label;
    }

    if (answerInfo.answer) {
      return answerInfo.answer;
    }

    return answerInfo;
  } catch (error) {
    return error;
  }
};

const isName = (answer) => {
  let firstName = "";
  let lastName = "";

  Object.keys(answer || {}).forEach((key) => {
    if (key?.toLowerCase() === "firstname") {
      firstName = answer[key].firstName;
    }

    if (key?.toLowerCase() === "lastname") {
      lastName = answer[key].lastname;
    }
  });

  return firstName + " " + lastName;
};
