import language from "../../../language";
import externalUserInfoQuery from "../../../utils/queries/externalUserInfoQuery";
import {
  validateEmployeeId,
  validateNationalId,
  validatePassportNumber,
} from "../../../utils/validateSpecialFields";

export default (that, field, e) => {
  let { error = {}, validFields = {}, firstName } = that.state;

  let value = e.target ? e.target.value : e;

  if (field === "nationalId" || field === "ippisNumber") {
    that.setState({ profileData: {} });
  }

  delete error[field];
  delete validFields[field];

  if (field === "nationalId") {
    error = validateNationalId({ props: that.props, field, value }).error;
    validFields = validateNationalId({
      props: that.props,
      field,
      value,
    }).validFields;
  }

  if (field === "passportNumber") {
    error = validatePassportNumber({ props: that.props, field, value }).error;
    validFields = validatePassportNumber({
      props: that.props,
      field,
      value,
    }).validFields;
  }

  // if (field === "ippisNumber") {
  //   error = validateEmployeeId({ props: that.props, field, value }).error;
  //   validFields = validateEmployeeId({
  //     props: that.props,
  //     field,
  //     value,
  //   }).validFields;
  // }

  that.setState({ [field]: value, error, validFields });

  //start external query

  // if (validFields["ippisNumber"]) {
  //   if (firstName === "") {
  //     error[firstName] =
  //       language[that.props.defaultLanguage].first_name_required;

  //     return that.setState({ error });
  //   }
  //   externalUserInfoQuery(that, {
  //     params: {
  //       employeeId: value,
  //       firstName: that.state.firstName,
  //     },
  //     fieldName: "ippisNumber",
  //   });
  // }

  if (validFields["nationalId"]) {
    if (firstName === "") {
      error[firstName] =
        language[that.props.defaultLanguage].first_name_required;

      return that.setState({ error });
    }
    externalUserInfoQuery(that, {
      params: {
        nid: value,
      },
      fieldName: "nationalId",
    });
  }
};
