import toastMessage from "../toastMessage";
import { getStorage } from "../storage";
import language from "../../language";
import { onAddNotification } from "../../actions/Notification";
import { defineRole } from "../handleAccessRoles";
import { apiInstance } from "../axiosInstance";

const API_URL = process.env.REACT_APP_ROOT_API;

const fetchData = async (that, params) => {
  const { lng = "english", page, limit, viewed } = params;
  try {
    const user = await getStorage();

    const { accessRole, role } = user;

    if (role !== "user") {
      const canRead = defineRole({
        roles: accessRole,
        menu: "Notifications",
        operation: "read",
      });

      if (!canRead) return;

      if (!user.token) return;
    }

    let paramsData = {
      page,
      limit,
      ...params,
    };

    delete paramsData.lng;

    if (role === "superAdmin") {
      paramsData.viewed = viewed;
      delete paramsData.viewedBy;
    }

    let headers = {
      Authorization: `Bearer ${user.token}`,
    };

    const options = {
      method: "GET",
      url: `${API_URL}/event/notification`,
      params: paramsData,
      headers,
    };

    const queryRes = await apiInstance(options);

    if (!queryRes.data.data) {
      countUnreadNotifications(that, queryRes.data);
      return;
    }

    let { data, count } = queryRes.data;

    countUnreadNotifications(that, data);

    return { data, count };
  } catch (error) {
    if (error?.response?.status === 403) {
      sessionStorage.clear();
      return (window.location.href = "/");
    }
    toastMessage("error", language[lng].unable_fetch_notifications);
  }
};

export default fetchData;

export const updateNotification = async ({ lng = "english", ...params }) => {
  try {
    const user = await getStorage();

    let headers = {
      Authorization: `Bearer ${user.token}`,
    };

    const options = {
      method: "PUT",
      url: `${API_URL}/event/notification`,
      data: params,
      headers,
    };

    await apiInstance(options);
  } catch (error) {
    toastMessage("error", language[lng].error_update_notifications);
  }
};

const countUnreadNotifications = async (that, notifications) => {
  const user = await getStorage();

  notifications.forEach((el) => {
    if (
      user?.id &&
      (!el.viewedBy || !el.viewedBy?.includes(user?.id)) &&
      el.body.status === "pending"
    ) {
      that.props.dispatch(
        onAddNotification({
          bodyID: el.body._id || el._id,
          notificationID: el._id,
          viewedBy: el.viewedBy,
        })
      );
    }
  });
};
