import loginVector from "../assets/phone-data.png";
import dashboardVector from "../assets/data-collection.png";

export default [
  {
    title: "Streamlined Field Activities Monitoring",
    description:
      "Enhance Field Monitoring with Mobile Compatible and Integrated Analytics Platform.",
    image: loginVector,
  },
  {
    title: "Transform Field Data Collection",
    description:
      "Optimize field data collection with comprehensive dynamic forms and integrated data mining.",
    image: dashboardVector,
  },
];
