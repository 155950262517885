import HomeScreen from "../screens/User/HomeScreen";
import SettingScreen from "../screens/AdminDashboard/SettingScreen";
import language from "../language";
import icons from "./icons";
import { onAddSidebarMenus } from "../actions/Sidebar";
import SurveysScreen from "../screens/User/Surveys/SurveysScreens";

const data = async (that) => {
  try {
    const lng = that.props.defaultLanguage || "english";

    const menusData = [
      {
        parentPath: "/user",
        path: `/home`,
        name: "Home",
        icon: icons.home,
        component: <HomeScreen />,
      },
      {
        parentPath: "/user",
        path: `/surveys`,
        name: language[lng].surveys,
        icon: icons.request,
        component: <SurveysScreen role="user" />,
      },
    ];

    let sidebarList = menusData.filter(async (el) => {
      return el;
    });
    const menus = [
      ...sidebarList,
      {
        parentPath: "/user",
        path: "/settings",
        name: language[lng].settings,
        icon: icons.setting,
        component: <SettingScreen />,
      },
    ];

    that.props.dispatch(onAddSidebarMenus(menus));
  } catch (error) {
    console.log(error);
    window.location.href = "/";
  }
};

export default data;
