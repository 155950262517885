import React from "react";
import "./styles.css";
import { Switch } from "../Input";
import { connect } from "react-redux";
import language from "../../language";

class TwoFactorAuthentication extends React.Component {
  state = {
    checked: false,
  };

  handleCheck = () => {
    this.setState({
      checked: !this.state.checked,
    });
  };

  render() {
    return (
      <div className="factorAuthentication-container">
        <Switch
          title={
            language[this.props.defaultLanguage].two_factor_authentication_label
          }
          checked={this.state.checked}
          onCheck={this.handleCheck.bind(this)}
          description={
            language[this.props.defaultLanguage]
              .two_factor_authentication_description
          }
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(TwoFactorAuthentication);
