import React from "react";
import FarmerRegionGender from "../Charts/Project/FarmerRegionGender";
import FarmerPerGender from "../Charts/Project/FarmerPerGender";
import YouthPerRegion from "../Charts/Project/YouthPerRegion";
import ListCandidates from "./ListCandidate";
import "./styles.css";
import language from "../../language";
import { connect } from "react-redux";
import { LocationFilter } from "../Filters";
import { Button } from "../Button";
import DateFilter from "../Filters/DateFilter";
import ServiceFilter from "../Filters/ServiceFilter";
import Counts from "./Counts";
class Analytics extends React.Component {
  state = {};

  handleToggle(isToggled) {
    this.setState({
      [isToggled]: !this.state[isToggled],
    });
  }

  render() {
    return (
      <>
        <div className="d-flex gap-2 mb-2">
          <LocationFilter />
          <ServiceFilter />
          {/* <DateFilter /> */}
        </div>
        <hr />
        <Counts />
        <div className="row">
          <div className="col-md-8">
            <div className="card mb-3">
              <div>
                <div className="chart-header">
                  <h3 className="text-disabled mb-2">
                    {(this.props.filters?.service?.label
                      ? this.props.filters?.service?.label
                      : "FSC and Farmer's") +
                      " " +
                      language[this.props.defaultLanguage]
                        .regions_regions_per_gender}
                  </h3>
                </div>
              </div>
              <div className="card-body" style={{ position: "relative" }}>
                <FarmerRegionGender />
              </div>
            </div>
            <div className="card">
              <div>
                <div className="chart-header">
                  <h3 className="text-disabled">
                    {(this.props.filters?.service?.label
                      ? this.props.filters?.service?.label
                      : "FSC and Farmer's") +
                      " " +
                      language[this.props.defaultLanguage].youth_region}
                  </h3>
                </div>
              </div>
              <div className="card-body" style={{ position: "relative" }}>
                <YouthPerRegion />
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="card mb-3" style={{ minHeight: 373 }}>
              <div>
                <div className="chart-header">
                  <h3 className="text-disabled">
                    {(this.props.filters?.service?.label
                      ? this.props.filters?.service?.label
                      : "FSC and Farmer's") +
                      " " +
                      language[this.props.defaultLanguage].farmers_per_gender}
                  </h3>
                </div>
              </div>
              <div className="card-body">
                <FarmerPerGender />
              </div>
            </div>
            <div className="card mb-3" style={{ minHeight: 373 }}>
              <div className="regions-counts">
                <div>
                  <div className="chart-header">
                    <h3 className="text-disabled">
                      {language[this.props.defaultLanguage].regions}
                    </h3>
                  </div>
                </div>
                <div
                  className="card-body"
                  style={{ height: 280, overflowY: "auto" }}
                >
                  <ListCandidates />
                </div>
                <div className="card-footer">
                  <Button
                    text={language[this.props.defaultLanguage].see_all_regions}
                    className="btn-sm"
                    onPress={() =>
                      (window.location.href = "/dashboard/analytics/regions")
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  const { filters } = state.Filters;
  return {
    defaultLanguage,
    filters,
  };
};

export default connect(mapStateToProps)(Analytics);
