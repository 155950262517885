import toastMessage from "../toastMessage";
import { getStorage } from "../storage";
import language from "../../language";
import { defineRole } from "../handleAccessRoles";
import formatSelectData from "../formatSelectData";
import { apiInstance } from "../axiosInstance";

const fetchData = async (lng = "english", params = {}) => {
  let {
    page,
    limit,
    active = undefined,
    outsideOrganization = false,
    type,
    id,
    accountType,
  } = params;
  try {
    const user = await getStorage();

    const { accessRole } = user;

    const canRead = defineRole({
      roles: accessRole,
      menu: active === false ? "Inactive Accounts" : "Active Accounts",
      operation: "read",
    });

    if (!canRead) return;

    // allow super admin to view all accounts
    if (user.role === "superAdmin") {
      outsideOrganization = undefined;
    }

    const options = {
      method: "GET",
      url: `/account`,
      params: {
        page: page,
        limit: limit,
        active,
        id,
        accountType: accountType,
      },
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    const queryRes = await apiInstance(options);

    if (type === "dropdown") {
      return formatSelectData(queryRes.data, "username", "_id");
    }

    let { data, count } = queryRes.data;

    if (!data) {
      data = queryRes.data;
    }

    return {
      data,
      count,
    };
  } catch (error) {
    console.log(error);
    toastMessage("error", language[lng].unable_fetch_accounts);
  }
};

export default fetchData;

export const deleteAccount = async (lng = "english", id) => {
  try {
    const user = await getStorage();

    const options = {
      method: "DELETE",
      url: `/account`,
      data: {
        id,
      },
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    await apiInstance(options);

    toastMessage("success", language[lng].success_delete_account);
  } catch (error) {
    toastMessage("error", language[lng].error_delete_account);
  }
};
