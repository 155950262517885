// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.list-item {
  display: flex;
  align-items: center;
  min-height: 40px;
  opacity: 1;
  transition: opacity 0.4s;
  cursor: pointer;
  padding: 0.5rem;
}

.list-item:hover {
  opacity: 0.8;
}

.list-item .list-img-container {
  width: 35px;
  height: 35px;
  border-radius: var(--border-radius-half);
  display: flex;
  align-items: center;
  justify-content: center;
}

.list-item .content {
  flex: 1 1;
}
.list-item .list-right-icon-container {
  opacity: 0.3;
}

.list-item img {
  aspect-ratio: 3/2;
  object-fit: contain;
  width: 100%;
  border-radius: var(--border-radius-half);
}

.list-item .list-item-name {
  text-transform: capitalize;
}

.list-item .icon-container span {
  font-size: 18px;
}
`, "",{"version":3,"sources":["webpack://./src/app/components/List/styles.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,gBAAgB;EAChB,UAAU;EACV,wBAAwB;EACxB,eAAe;EACf,eAAe;AACjB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,wCAAwC;EACxC,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,SAAO;AACT;AACA;EACE,YAAY;AACd;;AAEA;EACE,iBAAiB;EACjB,mBAAmB;EACnB,WAAW;EACX,wCAAwC;AAC1C;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE,eAAe;AACjB","sourcesContent":[".list-item {\r\n  display: flex;\r\n  align-items: center;\r\n  min-height: 40px;\r\n  opacity: 1;\r\n  transition: opacity 0.4s;\r\n  cursor: pointer;\r\n  padding: 0.5rem;\r\n}\r\n\r\n.list-item:hover {\r\n  opacity: 0.8;\r\n}\r\n\r\n.list-item .list-img-container {\r\n  width: 35px;\r\n  height: 35px;\r\n  border-radius: var(--border-radius-half);\r\n  display: flex;\r\n  align-items: center;\r\n  justify-content: center;\r\n}\r\n\r\n.list-item .content {\r\n  flex: 1;\r\n}\r\n.list-item .list-right-icon-container {\r\n  opacity: 0.3;\r\n}\r\n\r\n.list-item img {\r\n  aspect-ratio: 3/2;\r\n  object-fit: contain;\r\n  width: 100%;\r\n  border-radius: var(--border-radius-half);\r\n}\r\n\r\n.list-item .list-item-name {\r\n  text-transform: capitalize;\r\n}\r\n\r\n.list-item .icon-container span {\r\n  font-size: 18px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
